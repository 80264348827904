import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-signout-bar',
  templateUrl: './signout-bar.component.html',
  styleUrls: ['./signout-bar.component.scss'],
})
export class SignoutBarComponent implements OnInit {
  firebaseUser;

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private router: Router
  ) {
    this.firebaseUser = localStorage.getItem('user');
  }

  ngOnInit(): void {}

  close() {
    this.dialog.closeAll();
  }

  signOut() {
    this.loginService.signOut();
    this.dialog.closeAll();
  }
}
