<div>
  <mat-card>
    <mat-card-header>Stats</mat-card-header>
    <div class="dashboard-upper">
      <div style="width: 95%;">
        <h3 class="headings">Total Documents</h3>
        <p class="doc-amnts">{{totalDocument}}</p>
      </div>
      <div style="width: 95%;margin-left: 60px;">
        <h3 class="headings">Downloaded Documents</h3>
        <p class="doc-downloads">{{downloadCount}}</p>
      </div>
    </div>
  </mat-card>
  <div class="dashboard-lower-wrapper">
    <mat-card class="retuned-doc">
      <h3 class="headings">Returned Documents</h3>
      <div>
        <p class="doc-desc">There were <span>15</span> returned documents</p>
      </div>
    </mat-card>
    <mat-card class="completed-docs">
      <h3 class="headings">Completed Documents</h3>
      <div>
        <p class="doc-desc">There were <span>{{totalCompletedDocuments}}</span> completed documents</p>
      </div>
    </mat-card>
  </div>
</div>