<div fxLayoutAlign="center center">
  <mat-form-field fxFlex="40%">
    <input matInput (keyup)="doFilter($event)" placeholder="Search..." #input />
  </mat-form-field>
</div>
<table mat-table [dataSource]="dataSource" matSort>
  <!-- Name Column -->
  <ng-container matColumnDef="package">
    <th mat-header-cell *matHeaderCellDef>Package Name</th>
    <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef>Created Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.time.seconds * 1000 | date: "medium" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
    <td mat-cell *matCellDef="let element">
     
        <button  mat-button (click)="downloadModal(element.id)"><mat-icon>download</mat-icon>Download</button>
        <button  mat-button (click)="signDocument(element.id)"><mat-icon>download</mat-icon>Sign</button>
       
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
></mat-paginator>

