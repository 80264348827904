import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PDFDocument } from 'pdf-lib';
import { FormBMWService } from 'src/app/shared/services/form-bmw.service';
import { PackageService } from 'src/app/shared/services/package.service';

@Component({
  selector: 'app-dnd',
  templateUrl: './dnd.component.html',
  styleUrls: ['./dnd.component.scss']
})
export class DndComponent implements OnInit {
  files: any[] = [];

  arrayPdf = [];
  url;
  arrayItem;

  constructor(
    private packageService: PackageService,
    private formService: FormBMWService,
    private router: Router
  ) {}

  ngOnInit() {
    console.log('DndComponent.ngOnInit()');
    // this.formService.loadXML(localStorage.getItem('XML')).subscribe((data) => {
    //   this.formService.parseXML(data).then((info) => {
    //     console.log('my data', info);

    //     this.arrayItem = info;
    //   });
    // });
  }

  /**
   * on file drop handler
   */
  onFileDropped(event: any) {
    this.prepareFilesList(event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  async prepareFilesList(files: Array<any>) {
    console.log('file array', files);
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      console.log(this.files);
    }
    this.uploadFilesSimulator(0);

    console.log('files[0]', files[0]);

    var data = await files[0].text();
    localStorage.setItem('PDF', data);
    console.log('bmw pdf file data', data);

    // this.xmlService.firebaseXMLUpload(files[0]);

    await this.readPdf(files[0]);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getDocument(data) {
    var file = new Blob([data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  async readPdf(info) {
    // const url = info;

    // const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    this.arrayItem = await this.formService.parseXML(localStorage.getItem('XML'));
    
    // console.log("info: ",info);
    var data = await info.arrayBuffer();
    console.log('data: ', data);
    const pdf = await PDFDocument.load(data);
    const pdfDoc = pdf;

    await (
      await PDFDocument.load(data)
    )
      .getForm()
      .getFields()
      .forEach((field) => {
        // console.log(field.getName());
        this.arrayPdf.push({ value: field.getName() });
      });

    const form = pdfDoc.getForm();
    let count = 0;
    for (let x = 1; x < 78; x++) {
      count = count + 1;
      // console.log('item check', count);
      form.getTextField(this.arrayPdf[x].value).setText(this.arrayItem[x].item);
    }

    // const form = pdfDoc.getForm();
    // let count = 0;
    // for (let x = 1; x < 78; x++) {
    //   count = count + 1;
    //   console.log('item check', count);
    //   form.getTextField(this.arrayPdf[x].value).setText(this.arrayItem[x].item);
    // }

    const pdfBytes = await pdf.save();
    localStorage.setItem('PDFWithData', new TextDecoder('utf8').decode(pdfBytes));
    console.log('PDFWithData', pdfBytes);

    //localStorage.setItem('pdfItem', ""+pdfBytes)
    //this.packageService.createPackageDocument(pdfBytes);

    this.packageService.pdfLink = pdfBytes;
    //this.router.navigateByUrl('admin/iframe');
  }
}
