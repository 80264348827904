import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as xml2js from 'xml2js';

@Injectable({
  providedIn: 'root',
})
export class FormBMWService {
  constructor(private http: HttpClient) {}

  getXXMLData() {
    let url = 'assets/bmw.xml';
    return this.http.get<any>(url);
  }

  loadXML(info) {
    //let url = '/assets/bmw.xml';
    let url = info;
    return this.http.get(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'text/xml')
        .append('Access-Control-Allow-Methods', 'GET')
        .append('Access-Control-Allow-Origin', '*')
        .append(
          'Access-Control-Allow-Headers',
          'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method'
        ),
      responseType: 'text',
    });
  }

  parseXML(data) {
    return new Promise((resolve) => {
      var k: string | number,
        arr = [],
        parser = new xml2js.Parser({
          trim: true,
          explicitArray: true,
        });
      parser.parseString(data, function (err, result) {
        console.log(result);

        let xmlData = [];
        arr = result.topmostSubform;
        for (const item in arr) {
          xmlData.push({ item: arr[item][0] });
        }
        console.log('my array', xmlData);
        resolve(xmlData);
      });
    });
  }


  testByPass(){
    
  }
}
