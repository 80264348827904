import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  isFormSubmitted: boolean = false;
   
  uri ="../../../../assets/landing.jpg";

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private snackbarService: SnackbarService,
    private userService: UsersService,
    private router: Router
  ) {
    // Regex patterns
    const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  ngOnInit(): void {}

  submit() {
    this.isFormSubmitted = true;
    if (this.form.valid) {
      this.loginService
        .createEmailAndPassword(this.form.value.email, this.form.value.password)
        .then((data) => {
          this.snackbarService.openSnackBar('Successfully Created Profile');

          this.userService.addProfile({
            email: this.form.value.email,
            userId: data.user.uid,
          });

          this.router.navigateByUrl('login');
        })
        .catch((err) => {
          this.snackbarService.openSnackBar('Error: ' + err.message);
        });
    }
  }
}
