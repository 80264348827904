<mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper" (selectionChange)="onStepChange($event)">
  <mat-step>
    <ng-template matStepLabel>Upload XML</ng-template>

    <mat-card style="text-align: center; color: black">
      <mat-card-title>Upload Client Details Via XML</mat-card-title>
      <app-upload-xmlitem></app-upload-xmlitem>
    </mat-card>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Upload Document Or Select Template</ng-template>

    <mat-card>
      <h1 style="color: black; text-align: center">
        Upload Document Or Select Template
      </h1>
      <app-dnd></app-dnd>
    </mat-card>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Add Signer</ng-template>

    <app-add-signer-item></app-add-signer-item>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Send Application</ng-template>
    
    <app-iframe></app-iframe>
  </mat-step>
</mat-horizontal-stepper>
