import { TestWorkflowService } from 'src/app/shared/services/test-workflow.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { PackageService } from 'src/app/shared/services/package.service';
import { SignatureService } from 'src/app/services/signature/signature.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
  url = null;
  recipientName: string = '';
  recipientEmail: string = '';
  signatureType: string = '';

  constructor(
    private packageService: PackageService,
    private safePipe: SafePipe,
    private router: Router,
    private testWorkFlow: TestWorkflowService,
    private signatureService: SignatureService
  ) {}

  ngOnInit() {
    // this.getDocument();
  }

  public async getDetails(): Promise<void> {
    await setTimeout(function(){}, 100);
    console.log('IframeComponent.getDocument()');
    console.log("pdfLink: ", this.packageService.pdfLink);
    
    if (this.packageService.pdfLink==null) return;

    var file = new Blob([this.packageService.pdfLink], {
      type: 'application/pdf',
    });
    var fileURL = URL.createObjectURL(file);
    localStorage.setItem('file', fileURL)
    // window.open(fileURL);

    this.url = this.safePipe.transform(fileURL);
    this.recipientName = localStorage.getItem('SignerName');
    this.recipientEmail = localStorage.getItem('SignerEmail');
    this.signatureType = localStorage.getItem('SignatureType');

    console.log("IframeComponent url: ", this.url);
    console.log("IframeComponent fileURL: ", fileURL);
    console.log("IframeComponent recipientName: ", this.recipientName);
    console.log("IframeComponent recipientEmail: ", this.recipientEmail);
    console.log("IframeComponent signatureType: ", this.signatureType);
  }

  public clearURL(): void {
    console.log('IframeComponent.clearURL()');
    this.url = null;
    this.recipientName = '';
    this.recipientEmail = '';
    this.signatureType = '';
  }

  async sendDoc() {
    console.log('IframeComponent.sendDoc()');
    // this.signatureService.config(localStorage.getItem('accessToken'), 2000);
    // this.signatureService.addUserSH(this.recipientName, this.recipientEmail, this.signatureType);
    // await this.signatureService.createPackageSH(this.recipientName, this.recipientEmail, this.signatureType);
    // //this.signatureService.addElectronicSH(localStorage.getItem('package_id'), localStorage.getItem('documentID'))
    
    // this.testWorkFlow.createPackage("xml");
  }
}
