<mat-nav-list>
  <div class="profile-card" (click)="goToProfile()">
    <img
      src="../../../../assets/avatar.png"
      alt="Avatar"
      *ngIf="user?.photoURL == '' || user?.photoURL == null"
    />
    <img
      [src]="user?.photoURL"
      alt="Avatar"
      *ngIf="user?.photoURL != '' || user?.photoURL != null"
    />
    <div class="header">
      <h2>Personal Information</h2>
      <h4>{{ user?.name }} {{ user?.surname }}</h4>
      <h4>{{ user?.email }}</h4>
    </div>
  </div>

  <mat-divider></mat-divider>

  <h2 matSubheader>Pages</h2>

  <a
    mat-list-item
    routerLinkActive="link-items"
    routerLinkActive="list-item-active"
    routerLink="/admin"
    ><mat-icon>home</mat-icon>Dashboard</a
  >
  <a
    mat-list-item
    routerLinkActive="link-items"
    routerLinkActive="list-item-active"
    routerLink="/admin/documents"
    ><mat-icon>grading</mat-icon>
    Applications</a
  >

  <a
    mat-list-item
    routerLinkActive="link-items"
    routerLinkActive="list-item-active"
    routerLink="/admin/workflow"
    ><mat-icon>assignment</mat-icon>
    Start New Application</a
  >

  <!-- <a
    mat-list-item
    routerLinkActive="link-items"
    routerLinkActive="list-item-active"
    routerLink="/admin/iframe"
    ><mat-icon>assignment</mat-icon>
    iFrame</a
  > -->

  <a
  mat-list-item
  routerLinkActive="link-items"
  routerLinkActive="list-item-active"
  ><mat-icon>inventory 2</mat-icon>
  Templates</a
>

</mat-nav-list>
