<mat-card>
  <h1 style="text-align: center">Add Signer</h1>
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Signer</mat-label>
      <input matInput formControlName="signer" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Signature</mat-label>
      <mat-select matNativeControl required formControlName="signature">
        <mat-option value="E-Sign field">Eletronic</mat-option>
        <mat-option value="Digital Sign field">Digital</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <button mat-button class="signer-button" (click)="addSigner()">
      Add Signer
    </button> -->
    <div style="text-align: center;">
      <button mat-button class="signer-button" matStepperNext id="proceed">Proceed</button>
    </div>
  </form>
</mat-card>
