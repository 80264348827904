import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { toHexString } from 'pdf-lib';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private database: AngularFirestore) {}
  getDownload(package_id) {
    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' + package_id + '/base64?';

    const Http = new XMLHttpRequest();
    Http.open('GET', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/octet-stream');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    Http.send();

    Http.onload = (e) => {
      // console.log('download state', Http.responseText);

      let base64 = JSON.parse(Http.responseText);

      this.dataURItoBlob(base64.base64);

      const blob = this.dataURItoBlob(base64.base64);
      const url = URL.createObjectURL(blob);

      // to open the PDF in a new window
      window.open(url, '_blank');
    };
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  getDocument(data) {
    var file = new Blob([data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  countDownload() {
    this.database.collection('Download').add({ status: 'Successfully' });
  }

  getDownloadCount() {
    return this.database.collection('Download').get();
  }
}
