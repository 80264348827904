import { Component, OnInit } from '@angular/core';
import { DownloadService } from 'src/app/shared/services/download.service';
import { PackageService } from 'src/app/shared/services/package.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  downloadCount;
  totalDocument;
  totalCompletedDocuments;

  constructor(
    private downloadService: DownloadService,
    private packageService: PackageService
  ) {}

  ngOnInit(): void {
    this.getDownloadCount();
    this.getPackageCount();
  }

  getDownloadCount() {
    this.downloadService.getDownloadCount().subscribe((data) => {
      this.downloadCount = data.size;
    });
  }

  getPackageCount() {
    this.packageService.getPackageListWithOutUserID().subscribe((data) => {
      this.totalDocument = data.size;
      console.log(this.totalDocument);
    });
  }

  getTotalDocuments() {
    this.packageService.getTotalCompletedPackages().subscribe((data) => {
      this.totalCompletedDocuments = data.size;
    });
  }
}
