<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>menu</mat-icon>
    </button>
<!-- 
    <img src="../../../../assets/bmw-logo-svgrepo-com.svg" alt="" srcset="" style="width: 32px;">
    <img src="../../../../assets/bmwlogo.png" alt="" srcset="" style="width: 48px;">
    <img src="../../../../assets/bmwlogo.svg" alt="" srcset="" style="width: 48px;">
    <img src="../../../../assets/bmwlogo2.png" alt="" srcset="" style="width: 48px;"> -->

    <h1 style="text-align: center;padding-left: 15px;"></h1>
    <span class="example-spacer"></span>

    <button mat-button (click)="signOut()" class="logout_button">
      Logout
    </button>

  </mat-toolbar-row>
</mat-toolbar>