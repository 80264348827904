import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class IframeserviceService {
  user
  constructor(private usersService: UsersService) {
    this.getUser()
  } 

  getUser(){
    this.usersService.retrieveProfile().subscribe((data) => {
      this.user = data.data() as User;
    });
  }

  getIframe(packageID) {
    var apiUrl = 'https://uatapi.signinghub.co.za/v3/links/integration';
    var email = localStorage.getItem('email');
    var packageObject = packageID;

    const xhr = new XMLHttpRequest();
    xhr.open('POST', apiUrl, true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    var linkBody = {
      package_id: packageObject,
      language: "en-US",
      user_email: email,
      callback_url: "https://web.signinghub.co.za/",
      collapse_panels: "true"
    }

    xhr.send(JSON.stringify(linkBody));

    xhr.onload = (e) => {
      console.log('Link State', xhr.responseText)
      var LinkObject = JSON.parse(xhr.responseText);
      console.log('JSON', LinkObject);
      if (LinkObject != null) {
  
        localStorage.setItem('linkObject', xhr.responseText);
        console.log(localStorage.getItem('packageObject'));
      }
    }
  }
}
