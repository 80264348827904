
<mat-toolbar>


  
</mat-toolbar>
<div id="divider"></div>
<h1 class="title">Please Register Below:</h1>

<mat-card style="margin-top: 50px;" [ngStyle]="{'background': 'url(' +uri + ') no-repeat 0 0'}" >

 
  <mat-card class="mega_card">

    <img src="assets/bmw-logo-svgrepo-com.svg" id="image_form">
 
  <form [formGroup]="form" >

    <mat-form-field appearance="fill">
      <mat-label>email</mat-label>
      <input matInput 
      type="email"
      required
      formControlName="email"
      [ngClass]="{
        'is-invalid':
          (isFormSubmitted && form.controls.email.errors) ||
          (!form.controls.email.pristine &&
          form.controls.email.invalid)
      }"
      />
    </mat-form-field>

     <!-- Validation messages -->
     <div
     class="invalid-feedback"
     *ngIf="
       (isFormSubmitted && form.controls.email.errors) ||
       (!form.controls.email.pristine &&
       form.controls.email.invalid)
     "
   >
   <div *ngIf="form.controls.email.errors.required">
    Email is required
  </div>
  <div *ngIf="form.controls.email.errors.pattern">
    Enter valid email
  </div>
</div>



<mat-form-field appearance="fill" style=" margin-top: 5px;">
  <mat-label>Password</mat-label>
  <input matInput  
  type="password"
      required
      formControlName="password"
      [ngClass]="{
        'is-invalid':
          (isFormSubmitted && form.controls.password.errors) ||
          (!form.controls.password.pristine &&
          form.controls.password.invalid)
      }" />
</mat-form-field>

<div
      class="invalid-feedback"
      *ngIf="
        (isFormSubmitted && form.controls.password.errors) ||
        (!form.controls.password.pristine &&
        form.controls.password.invalid)
      "
    >
      <div *ngIf="resetForm.controls.password.errors.required">
        Password is required
      </div>
      <div *ngIf="resetForm.controls.password.errors.minlength">
        Minimum 8 characters required
      </div>
    </div>


    <button mat-button style="background-color: rgb(41, 181, 228); color: black;  margin-top: 5px;"(click)="submit() ">Submit</button>

   

    <table>
      <tr>
        <td>
          <a [routerLink]="['']" style="color:  rgb(41, 181, 228); ">Home</a>
        </td>
        <td>
          <a [routerLink]="['/login']" style="color:  rgb(41, 181, 228); float: right;">Login</a>
        </td>
      </tr>
    </table>

   
  </form>
</mat-card>




</mat-card>
