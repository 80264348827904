import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PackageObject } from 'src/app/shared/models/packageObject';
import { User } from 'src/app/shared/models/user';
import { PackageService } from 'src/app/shared/services/package.service';
import { UsersService } from 'src/app/shared/services/users.service';
import { SignoutBarComponent } from 'src/app/shared/widgets/signout-bar/signout-bar.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {


  //Gauge

  gaugeType = "semi";
  gaugeValue = 5;
  gaugeLabel1 = "Approved";
  gaugeLabel2 = "Total";
  gaugeLabel3 = "Pending";
  gaugeLabel4 = "Decline";
  gaugeAppendText = "";

//

  arrayUser: any;
  countUsers;
  countDocuments;
  documentInProgressCount;
  documentCompletedCount;
  user = {} as User;
  uri ="../../../../assets/BMW_Figma_Logo.svg";
  count;
  constructor(
    private router: Router,
    private users: UsersService,
    public dialog: MatDialog,
    private packageService: PackageService
  ) {}

  ngOnInit(): void {
    this.getProfileDetail();
    this.getDocumentCompletedCount();
    this.getDocumentInProgressCount();

    this.users.loadXML();
    this.users.loadXML().subscribe((data) => {
      this.users.parseXML(data).then((info) => {
        this.arrayUser = info;

        this.countUsers = this.arrayUser.length;
      });
    });

    this.packageService.getPackageCountByUerWithoutUserID().subscribe((data) => {
        this.countDocuments = data.size;
    });

    // setInterval(() => { 
    //   this.getRequiredSignCount();
    // }, 60000);
  }

  goClients() {
    this.router.navigateByUrl('clients');
  }

  goDocuments() {
    this.router.navigateByUrl('admin/documents');
  }

  signOut() {
    const dialogRef = this.dialog.open(SignoutBarComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`SignoutBarComponent Dialog result: ${result}`);
    });
  }

  getProfileDetail() {
    this.users.retrieveProfile().subscribe((data) => {
      this.user = data.data() as User;
    });
  }

  getDocumentInProgressCount() {
    this.packageService.getInProgressPackages().subscribe((data) => {
      this.documentInProgressCount = data.size;
    });
  }

  getDocumentCompletedCount() {
    this.packageService.getCompletedPackages().subscribe((data) => {
      this.documentCompletedCount = data.size;
    });
  }


  goToRequiredSigned(){
    this.router.navigateByUrl('admin/required')
  }

  getRequiredSignCount(){
    this.packageService.getCountOfToBeSigned().subscribe(data => {
      this.count = 0;
      data.map(e => {
        let object =e.payload.doc.data() as PackageObject;
        if(object.status == "INPROGRESS" || object.status == "PENDING" ){
          this.count = this.count + 1;
        }
      })
    })
  }

  goToWorkFlow(){
    this.router.navigateByUrl('admin/workflow');
  }

}
