import { element } from 'protractor';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientDetailsModalComponent } from '../client-details-modal/client-details-modal.component';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';
import { PackageService } from 'src/app/shared/services/package.service';
import { ClientAddModalComponent } from '../client-add-modal/client-add-modal.component';
import { XmladdService } from 'src/app/shared/services/xmladd.service';
import { Subscription } from 'rxjs';
import * as xml2js from 'xml2js';
import { TestWorkflowService } from 'src/app/shared/services/test-workflow.service';


@Component({
  selector: 'app-client-applications',
  templateUrl: './client-applications.component.html',
  styleUrls: ['./client-applications.component.scss'],
})
export class ClientApplicationsComponent implements OnInit {
  arrayPdf = [];
  arrayItem;
  private queriesSubscription: Subscription;

  displayedColumns: string[] = [
    'position',
    'fullName',
    'email',
    'telphone',
    'action',
  ];
  dataSource;

  arrayUser;

  constructor(
    private router: Router,
    private users: UsersService,
    public dialog: MatDialog,
    private packageService: PackageService,
    private xmlAddService: XmladdService,
    private testWorkflowService: TestWorkflowService
  ) {}

  ngOnInit(): void {
    // this.users.loadXML();
    // this.users.loadXML().subscribe((data) => {
    //   this.users.parseXML(data).then((info) => {
    //     console.log('test', info);

    //     this.arrayUser = info;

    //     this.dataSource = info;
    //   });
    // });
    
    // console.log('users', this.arrayUser);
    this.getClients();
  }

  goBack() {
    this.router.navigateByUrl('detail');
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  startWorkflow(element) {
    // this.packageService.createPackageWithoutUserID();
    this.xmlAddService.startWorkflow(element.id).subscribe((data) => {
      console.log(data.data())
      var builder = new xml2js.Builder();
      var xml = builder.buildObject(data.data())
      this.testWorkflowService.createPackage(xml)
    })
  }

  getDocument(data) {
    var file = new Blob([data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  openClient(element) {
    const dialogRef = this.dialog.open(ClientDetailsModalComponent, {
      width: '600px',
      data: { object: element },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  addClient() {
    const dialogRef = this.dialog.open(ClientAddModalComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getClients() {
    this.xmlAddService.xmlGetClients().subscribe(result => {
      this.dataSource = result;
      console.log(this.dataSource)
    })
  }

  removeApplication(element) {
    this.xmlAddService.xmlRemoveClient(element.id)
  }
}
