import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { HeaderComponent } from './navigation/header/header.component';
import { MaterialImportsModule } from '../material-imports/material-imports.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SigninComponent } from './auth/sign-in/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DndComponent } from './dnd/dnd.component';
import { DndDirective } from './dnd/dnd.directive';
import { ProgressComponent } from './dnd/progress/progress.component';

import { ReCaptchaModule } from 'angular-recaptcha3';
import { environment } from 'src/environments/environment';
import { ResetComponent } from './auth/reset/reset.component';
import { ClientApplicationsComponent } from './client-applications/client-applications.component';
import { ClientDetailsModalComponent } from './client-details-modal/client-details-modal.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './auth/register/register.component';

import { AddSignerComponent } from './add-signer/add-signer.component';
import { RequiredSignComponent } from './required-sign/required-sign.component';
import { ClientAddModalComponent } from './client-add-modal/client-add-modal.component';
import { StepperComponent } from './stepper/stepper.component';
import { AddSignerItemComponent } from './stepper/add-signer-item/add-signer-item.component';
import { UploadXMLItemComponent } from './stepper/upload-xmlitem/upload-xmlitem.component';
import { XMLDirective } from './stepper/upload-xmlitem/xmlDirective';
import { LoginComponent } from './auth/login/login.component';
import { IframeComponent } from './iframe/iframe.component';
import { SafePipe } from '../shared/pipes/safe.pipe';



@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    SigninComponent,
    DndComponent,
    DndDirective,
    ProgressComponent,
    ResetComponent,
    ClientApplicationsComponent,
    ClientDetailsModalComponent,
    ProfileComponent,
    RegisterComponent,
    AddSignerComponent,
       RequiredSignComponent,
       ClientAddModalComponent,
       StepperComponent,
       AddSignerItemComponent,
       UploadXMLItemComponent,
       XMLDirective,
       LoginComponent,
       IframeComponent,
       SafePipe,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialImportsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
   
    ReCaptchaModule.forRoot({
      invisible: {
          sitekey: environment.recaptcha_site_key, 
      },
      normal: {
          sitekey: environment.recaptcha_site_key, 
      },
      language: 'en'
  }),
  ],
  exports: [HeaderComponent, SidebarComponent, DndComponent, ClientApplicationsComponent, AddSignerItemComponent, UploadXMLItemComponent, SafePipe],
  providers:[SafePipe]
})
export class ComponentsModule {}


