<h1 class="title">Please Login Below:</h1>

<mat-card
  style="margin-top: 20px"
  class="mat_card_outer"
>
  <mat-card class="mega_card">
    <img src="assets/bmw-logo-svgrepo-com.svg" id="image_form" />

    <form [formGroup]="userLoginForm" class="input_container">
      <!--  -->

      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          required
          formControlName="email"
          [ngClass]="{
            'is-invalid':
              (isFormSubmitted && userLoginForm.controls.email.errors) ||
              (!userLoginForm.controls.email.pristine &&
                userLoginForm.controls.email.invalid)
          }"
        />
      </mat-form-field>

      <!-- Validation messages -->
      <div
        class="invalid-feedback"
        *ngIf="
          (isFormSubmitted && userLoginForm.controls.email.errors) ||
          (!userLoginForm.controls.email.pristine &&
            userLoginForm.controls.email.invalid)
        "
      >
        <div *ngIf="userLoginForm.controls.email.errors.required">
          Email is required
        </div>
        <div *ngIf="userLoginForm.controls.email.errors.pattern">
          Enter valid email
        </div>
      </div>

      <mat-form-field appearance="fill" style="margin-top: 5px">
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          required
          formControlName="password"
          [ngClass]="{
            'is-invalid':
              (isFormSubmitted && userLoginForm.controls.password.errors) ||
              (!userLoginForm.controls.password.pristine &&
                userLoginForm.controls.password.invalid)
          }"
        />
      </mat-form-field>

      <div
        class="invalid-feedback"
        *ngIf="
          (isFormSubmitted && userLoginForm.controls.password.errors) ||
          (!userLoginForm.controls.password.pristine &&
            userLoginForm.controls.password.invalid)
        "
      >
        <div *ngIf="userLoginForm.controls.password.errors.required">
          Password is required
        </div>
        <div *ngIf="userLoginForm.controls.password.errors.minlength">
          Minimum 8 characters required
        </div>
      </div>

      <button
        mat-button
        style="
          background-color: #2A88B7;
          color: black;
          margin-top: 5px;
        "
        (click)="submit()"
      >
        Login
      </button>

      <table>
        <tr>
          <td>
            <a [routerLink]="['/register']" style="color: #2A88B7"
              >Create Account</a
            >
          </td>
          <td>
            <a
              [routerLink]="['/reset']"
              style="color: #2A88B7; float: right"
              >Forgot Password</a
            >
          </td>
        </tr>
      </table>

      <button
        mat-button
        style="background-color: #fff; color: black; margin-top: 5px"
      >
        <img src="assets/gsuite.jpg" height="15" /> Google
      </button>
    </form>
  </mat-card>
</mat-card>
