import { Component, OnInit } from '@angular/core';
import { XMLData } from 'src/app/shared/models/xmlData';
import { FormBMWService } from 'src/app/shared/services/form-bmw.service';
import { PackageService } from 'src/app/shared/services/package.service';
import { TestWorkflowService } from 'src/app/shared/services/test-workflow.service';
import { XmladdService } from 'src/app/shared/services/xmladd.service';

@Component({
  selector: 'app-upload-xmlitem',
  templateUrl: './upload-xmlitem.component.html',
  styleUrls: ['./upload-xmlitem.component.scss'],
})
export class UploadXMLItemComponent implements OnInit {
  files: any[] = [];
  arrayItem;

  constructor(
    private packageService: PackageService,
    private xmlService: XmladdService,
    private formService: FormBMWService,
    private testWorkflowSErvice: TestWorkflowService
  ) {}

  ngOnInit(): void {
  }

  /**
   * on file drop handler
   */
  onFileDropped(event: any) {
    // event.preventDefault();
    // const file = event.target.files[0];
    this.prepareFilesList(event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 10;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
   async prepareFilesList(files: Array<any>) {
    console.log('file array', files);
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      console.log(this.files);
    }
    this.uploadFilesSimulator(0);

    // let fileField: any;
    // fileField = document.getElementById('fileDropRef');
    // let img: any;

    console.log('files[0]', files[0]);
    // console.log('fileField.files[0]', fileField.files[0]);

    // var fReader = new FileReader();
    // fReader.readAsDataURL(fileField.files[0]);
    // fReader.onloadend = function (event) {
    //   img = document.getElementById('fileDropRef');
    //   img = event.target.result;
    //   console.log('the path', img);
    // };

    var data = await files[0].text();
    localStorage.setItem('XML', data);
    console.log('bmw xml file data', data);

    // var reader = new FileReader();
    // reader.onload = function(e) {
    //   localStorage.setItem('XML', reader.result as string);
    //   console.log('bmw xml file data', reader.result);
    // }
    // reader.readAsText(files[0]);

    // setTimeout(() => {
    //   // this.formService.loadXML(img).subscribe(data => {

    //   //   this.formService.parseXML(data).then(info => {

    //   //     console.log('my data',info);

    //   //     this.arrayItem = info;
    //   //   })

    //   // })

    //   localStorage.setItem('XML', img);

    //   // this.testWorkflowSErvice.retrieveXML(img);
    // }, 1000);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getUrl() {
    this.xmlService.getFirebaseXML().subscribe((data) => {
      data.map((e) => {
        let object = e.payload.doc.data() as XMLData;

        console.log('object', object);

        this.formService.loadXML(object.downloadUrl).subscribe((data) => {
          this.formService.parseXML(data).then((info) => {
            console.warn('my data', info);

            this.arrayItem = info;
          });
        });
      });
    });
  }

  testByPass() {
    let apiUrl =
      'https://firebasestorage.googleapis.com/v0/b/bmwflow-94283.appspot.com/o/BMWXML%2FBMW1720211437?alt=media&token=12167029-de57-4117-9baf-e13a23acb813';
    const Http = new XMLHttpRequest();
    Http.open('GET', apiUrl, true);

    Http.setRequestHeader('Access-Control-Allow-Origin', '*');
    Http.setRequestHeader('Access-Control-Allow-Credentials', 'true');
    Http.setRequestHeader('Access-Control-Allow-Methods', 'GET');
    Http.setRequestHeader('Access-Control-Allow-Headers', 'Authorization');
    Http.setRequestHeader('Access-Control-Max-Age', '3600');

    Http.send();

    Http.onload = (e) => {
      let base64 = JSON.parse(Http.responseText);
    };
  }

  getXMLService() {
    let apiUrl =
      'https://firebasestorage.googleapis.com/v0/b/bmwflow-94283.appspot.com/o/BMWXML%2FBMW1720211437?alt=media&token=12167029-de57-4117-9baf-e13a23acb813';
    this.xmlService.getClient(apiUrl).subscribe((data) => {
      console.log(data);
    });
  }
}
