import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { FormBMWService } from './form-bmw.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  arrayPdf = [];
  url;
  arrayItem;

  loadedDocuments = new Subject<Document[]>();
  private querySub: Subscription[] = [];
  private availableDocuments: Document[] = [];

  constructor(
    private router: Router,
    private formService: FormBMWService,
  ) {}
  // Upload document - uploadDoc.html
  async uploadDoc() {
    console.log('DocumentService.uploadDoc()');
    //this.retrieveXML();
    //this.packageService.createPackage(file);
    
    var package_id = localStorage.getItem('package_id');
    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      package_id +
      '/documents';
    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/octet-stream');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );
    Http.setRequestHeader('x-file-name', 'First_Test.pdf');
    Http.setRequestHeader('x-convert-document', 'true');
    Http.setRequestHeader('x-source', 'API');

    const pdfBytes = await this.readPdf();

    Http.send(pdfBytes);

    Http.onload = (e) => {
      console.log('Document state', Http.responseText);

      var documentObject = JSON.parse(Http.responseText);
      localStorage.setItem('documentID', documentObject.documentid);
      //this.tempService.addTemplate('verification document');
    };
  }

  dataURItoArrayBuffer(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ab;
  }

  async readPdf() {
    const url = '/assets/bmw.pdf';

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdf = await await PDFDocument.load(existingPdfBytes);
    const pdfDoc = pdf;

    await (
      await PDFDocument.load(existingPdfBytes)
    )
      .getForm()
      .getFields()
      .forEach((field) => {
        console.log(field.getName());
        this.arrayPdf.push({ value: field.getName() });
      });

    const form = pdfDoc.getForm();

    let count = 0;
    for (let x = 1; x < 78; x++) {
      count = count + 1;
      console.log('item check', count);
      form.getTextField(this.arrayPdf[x].value).setText(this.arrayItem[x].item);
    }

    const pdfBytes = await pdf.save();
    return pdfBytes;
  }

  // retrieveXML() {
  //   this.formService.loadXML();

  //   this.formService.loadXML().subscribe((data) => {
  //     this.formService.parseXML(data).then((info) => {
  //       console.log('my data', info);

  //       this.arrayItem = info;
  //     });
  //   });

  //   console.log('my array', this.arrayItem);
  // }


  async uploadDocNoFirebase() {
    console.log('DocumentService.uploadDocNoFirebase()');
  //  this.retrieveXML();
    //this.packageService.createPackage(file);

    var package_id = localStorage.getItem('package_id');
    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      package_id +
      '/documents';
    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/octet-stream');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );
    Http.setRequestHeader('x-file-name', 'First_Test.pdf');
    Http.setRequestHeader('x-convert-document', 'true');
    Http.setRequestHeader('x-source', 'API');

    const pdfBytes = await this.readPdf();

    Http.send(pdfBytes);

    Http.onload = (e) => {
      console.log('Document state', Http.responseText);

      var documentObject = JSON.parse(Http.responseText);
      localStorage.setItem('documentID', documentObject.documentid);
     // this.tempService.addTemplate('verification document');
     //this.addUserService.addUser(package_id);
   //  this.signatureService.addBlock(package_id, documentObject.documentid);

   this.router.navigateByUrl('admin/addSigner');
    };
  }

  uploadDocItem(item) {
    console.log('DocumentService.uploadDocItem()');
    //  this.retrieveXML();
      //this.packageService.createPackage(file);
  
      var package_id = localStorage.getItem('package_id');
      var apiUrl =
        'https://uatapi.signinghub.co.za/v3/packages/' +
        package_id +
        '/documents';
      const Http = new XMLHttpRequest();
      Http.open('POST', apiUrl, true);
      Http.setRequestHeader('Content-type', 'application/octet-stream');
      Http.setRequestHeader(
        'Authorization',
        'Bearer' + ' ' + localStorage.getItem('accessToken')
      );
      Http.setRequestHeader('x-file-name', 'First_Test.pdf');
      Http.setRequestHeader('x-convert-document', 'true');
      Http.setRequestHeader('x-source', 'API');
  
      const pdfBytes = item;
  
      Http.send(pdfBytes);
  
      Http.onload = (e) => {
        console.log('Document state', Http.responseText);
  
        var documentObject = JSON.parse(Http.responseText);
        localStorage.setItem('documentID', documentObject.documentid);
       // this.tempService.addTemplate('verification document');
       //this.addUserService.addUser(package_id);
     //  this.signatureService.addBlock(package_id, documentObject.documentid);
  
     //this.router.navigateByUrl('admin/addSigner');
      };
    }
}
