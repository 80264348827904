import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import * as xml2js from 'xml2js';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  usersObject ={
    email: '',
    signature: ''
  }
  
  selectedFile = null;
  uploadPercent: any;
  downloadU: any;
  uniqkey: any;
  today: any = new Date();
  date =
    this.today.getDate() +
    '' +
    (this.today.getMonth() + 1) +
    '' +
    this.today.getFullYear();
  time = this.today.getHours() + '' + this.today.getMinutes();
  dateTime = this.date + '' + this.time;
  progress;

  constructor(
    private http: HttpClient,
    private database: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  //Request for XML Data
  loadXML() {
    let url = '/assets/bmw.xml';
    return this.http.get(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'text/xml')
        .append('Access-Control-Allow-Methods', 'GET')
        .append('Access-Control-Allow-Origin', '*')
        .append(
          'Access-Control-Allow-Headers',
          'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method'
        ),
      responseType: 'text',
    });
  }

  //Converts XML Client Data to Object Array
  parseXML(data) {
    return new Promise((resolve) => {
      var k: string | number,
        arr = [],
        parser = new xml2js.Parser({
          trim: true,
          explicitArray: true,
        });
      parser.parseString(data, function (err, result) {
        console.log(result);
        let xmlData = [];
        arr = result.topmostSubform;
        console.log('my result', arr);
        xmlData.push(arr);
        console.log('my array', xmlData);
        resolve(xmlData);
      });
    });
  }

  //Add Profile firebase
  addProfile(profile) {
    return this.database
      .collection('Profile')
      .doc(localStorage.getItem('user'))
      .set(profile);
  }

  //Retrieve profile firebase
  retrieveProfile() {
    return this.database
      .collection('Profile')
      .doc(localStorage.getItem('user'))
      .get();
  }

  retrieveProfileImage() {
    return this.database
      .collection('Profile')
      .doc(localStorage.getItem('user'))
      .snapshotChanges();
  }

  uploadPhoto(event) {
    const file = event.target.files[0];
    console.log('pic', file);
    this.uniqkey = 'PIC' + this.dateTime;
    const filePath = this.uniqkey;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadU = fileRef.getDownloadURL().subscribe((urlPath) => {
            console.log(urlPath);

            this.database
              .doc('Profile/' + localStorage.getItem('user'))
              .update({
                photoURL: urlPath,
              });
            this.uploadPercent = null;
          });
        })
      )
      .subscribe();
    return (this.uploadPercent = task.percentageChanges());
  }
}
