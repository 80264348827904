
<h2 style="text-align: center;">Notifications</h2>
<div fxLayoutAlign="center center">
    <mat-form-field fxFlex="40%">
      <input matInput (keyup)="doFilter($event)" placeholder="Search..." #input />
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- Name Column -->
    <ng-container matColumnDef="sender">
      <th mat-header-cell *matHeaderCellDef>Sender</th>
      <td mat-cell *matCellDef="let element">{{element?.sender}}</td>
    </ng-container>
  
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef>Created Time</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.time.seconds * 1000 | date: "medium" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef>Completed Time</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.completedTime }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element?.status }}</td>
    </ng-container>
  
    <ng-container matColumnDef="sign">
      <th mat-header-cell *matHeaderCellDef>Sign</th>
      <td mat-cell *matCellDef="let element">
    
        
          <button mat-menu-item (click)="signDocument(element)"><mat-icon>launch</mat-icon>Sign</button>
     
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
