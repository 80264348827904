<mat-card>
  <h1 style="text-align: center; color: black">
    Please enter recipient details
  </h1>
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Recipient name</mat-label>
      <input matInput formControlName="signerName" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Recipient email</mat-label>
      <input matInput formControlName="signerEmail" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Signature type</mat-label>
      <mat-select matNativeControl required formControlName="signature">
        <mat-option value="Electronic">Electronic</mat-option>
        <mat-option value="Digital">Digital</mat-option>
        <mat-option value="Advanced Electronic">Advanced Electronic</mat-option>
      </mat-select>
    </mat-form-field>

    <div style="text-align: center;">
      <button mat-button class="signer-button" (click)="saveSigner()" matStepperNext id="proceed">Proceed</button>
    </div>
  </form>
</mat-card>
