<mat-toolbar>
  <span class="example-spacer"></span>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div class="container" style="margin-top: 10px">
  <div>
    <h1 style="font-weight: 500">
      Finance Application for: {{ client.Txt_CrAppCorporateCName }}
    </h1>
  </div>
  <hr />
  <div>
    <h2 style="font-weight: 400">Personal Information</h2>
    <div>
      <div class="personal_details">
        <p>Full name: {{ client.Txt_CrAppCorporateCName}}</p>
        <p>Email: {{ client.Txt_CrAppCorporateEmailAddr }}</p>
      </div>
      <div class="personal_details">
        <p>Telephone:{{ client.Txt_CrAppCorporateCellphone }}</p>
      </div>
    </div>
  </div>
  <hr />
  <h2 style="font-weight: 400">Application Information</h2>
</div>

<div style="margin-top: 10px">
  <h2 style="font-weight: 400">Branch Information</h2>

  <div>
    <p>Branch Master Description: {{ client.Txt_BranchMasterBrnDesc }}</p>
    <p>Branch Address BPS Code: {{ client.Txt_CrAppAddressBPSCode }}</p>
    <p>Branch Address BPS Line 1: {{ client.Txt_CrAppAddressBPSLine1 }}</p>
    <p>Branch Address Bus Line 1: {{ client.Txt_CrAppAddressBUSLine1 }}</p>
    <p>Branch Address Bus Line 2: {{ client.Txt_CrAppAddressBUSLine2 }}</p>
  </div>
</div>
