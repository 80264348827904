<mat-card style="margin-left: auto; margin-right: auto">
    <div>
      <p>Please select a document to create a trusted document.</p>
      <form id="image-form" action="#">
        <input
          id="mediaCapture"
          type="file"
          accept="application/xml"
          (change)="saveImageMessage($event)"
          #hiddenfileinput
          style="display: none"
        />
        <button
          (click)="hiddenfileinput.click()"
          class="waves-effect waves-light btn"
          style="display: flex"
        >
          <i style="padding-right: 15px" class="material-icons left">code</i
          >Select XML
        </button>
      </form>
    </div>
  </mat-card>
