// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC_nEYvsaO3LC1RELvz3IbhoeUKtaEdB78",
    authDomain: "bmwflow-94283.firebaseapp.com",
    projectId: "bmwflow-94283",
    storageBucket: "bmwflow-94283.appspot.com",
    messagingSenderId: "353284345355",
    appId: "1:353284345355:web:c32e79385262040ff9c10e",
    measurementId: "G-WWHY0RVJ0Y",
  },
  recaptcha_site_key: '6LcpwDQbAAAAAAroPoyTEBVcb9JnHDxAzqZMydKI',
  recaptcha_secret_key: '6LcpwDQbAAAAAEGv_MPlHSXo-_nIIYqHTBQZgjKe'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
