import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignatureService } from 'src/app/services/signature/signature.service';

@Component({
  selector: 'app-add-signer',
  templateUrl: './add-signer.component.html',
  styleUrls: ['./add-signer.component.scss'],
})
export class AddSignerComponent implements OnInit {
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private addSignerService: SignatureService
  ) {
    this.form = this.formBuilder.group({
      signer: ['', Validators.required],
      signature: ['', Validators.required],
    });
  }

  addSigner() {
    if (this.form.valid) {
      this.addSignerService.addUser(
        this.form.value.signer,
        this.form.value.signature
      );
    }
  }

  ngOnInit(): void {}
}
