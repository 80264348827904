import { ConstantPool } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { PackageObject } from 'src/app/shared/models/packageObject';
import { PackageService } from 'src/app/shared/services/package.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  packageObject = {} as PackageObject;

  apiUrl:string = 'https://uatapi.signinghub.co.za/v3/packages/';
  accessToken: string = '';
  timeout: number = 2000;

  defaultHeaders = {
    'Content-type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer '
  };

  constructor(
    private packageService: PackageService,
    private database: AngularFirestore,
    private router: Router
  ) {}

  config(accessToken: string, timeout: number) {
    this.accessToken = accessToken;
    this.timeout = timeout;
    this.defaultHeaders.Authorization += accessToken;
  }

  callSH(serviceUrl: string, body: string | Object, callback?: { (xhr: XMLHttpRequest): void; }, headers: Object = this.defaultHeaders) {
    const xhr = new XMLHttpRequest();
    
    xhr.open('POST', this.apiUrl + serviceUrl, true);

    for (const h in headers) xhr.setRequestHeader(h, headers[h]);
    
    xhr.onload = function() {
      if (xhr.readyState === 4) {
          if (xhr.status === 200) {
              callback(xhr);
          } else {
              console.error('callSH ' + serviceUrl + ': ', xhr.statusText);
              console.error('callSH ' + serviceUrl + ': ', xhr.responseText);
          }
      }
    };

    xhr.onerror = function (e) {
      console.error('callSH ' + serviceUrl + ': ', xhr.statusText);
      console.error('callSH ' + serviceUrl + ': ', xhr.responseText);
    };
    
    xhr.ontimeout = function () {
      console.error('callSH ' + serviceUrl + ': timed out.');
    };
    
    xhr.timeout = this.timeout;
    
    xhr.send(body instanceof Object ? JSON.stringify(body) : body); 
  }

  addUserSH(name: string, email: string, signature: string) {
    try {
      console.log('SignatureService.addUserSH()');

      let packageName = localStorage.getItem('packageName');
      let packageID = localStorage.getItem('package_id');
      let documentID = localStorage.getItem('documentID');
      
      console.log('name: ', name);
      console.log('email: ', email);
      console.log('signature: ', signature);
      console.log('packageName: ', packageName);
      console.log('packageID: ', packageID);
      console.log('documentID: ', documentID);
      console.log('accessToken: ', this.accessToken);

      let user = [
        {
          user_email: email,
          user_name: name,
          role: 'SIGNER',
          email_notification: true,
          signing_order: 1,
        },
      ];

      this.callSH(packageID + '/workflow/users', user, (xhr: XMLHttpRequest) => {
        console.log('addUserSH: ', xhr.responseText);
      });
    } catch (error) {
      console.error('SignatureService.addUserSH()', error.stack);
    }
  }

  async createPackageSH(name: string, email: string, signature: string) {
    console.log('SignatureService.createPackageSH()');

    this.packageObject.id = localStorage.getItem('package_id');
    this.packageObject.name = localStorage.getItem('packageName');
    this.packageObject.time = new Date();
    this.packageObject.userId = localStorage.getItem('user');
    this.packageObject.signerName = email;
    this.packageObject.signerEmail = email;
    this.packageObject.sender = localStorage.getItem('email');
    this.packageObject.status = '';
    
    console.log('packageObject: ', this.packageObject);

    await this.database.collection('PackageList').add(this.packageObject);

    this.addSignatureFieldSH(this.packageObject.id, localStorage.getItem('documentID'), signature);
  }

  addPackageSH() {
    try {
      console.log('SignatureService.addPackageSH()');

      this.callSH('', { package_name: 'BMW_' + moment().format('YYYYMMDDHHmmss') }, (xhr: XMLHttpRequest) => {
        console.log('addPackageSH: ', xhr.responseText);
        localStorage.setItem('package_id', JSON.parse(xhr.responseText).package_id);
      });
    } catch (error) {
      console.error('SignatureService.addPackageSH()', error.stack);
    }
  }


        // if (Http.responseText) {
        //   let packageValue = JSON.parse(Http.responseText);

        //   localStorage.setItem('package_id', packageValue.package_id);

        //   this.documentService.uploadDoc();
        //   this.packageObject.id = packageValue.package_id;

        //   this.packageObject.name = packageName;
        //   this.packageObject.time = new Date();
        //   this.packageObject.userId = localStorage.getItem('user');

        //   this.addPackageWithOutUserID(this.packageObject)
        //     .then(() => {
        //       console.log('package created');
        //     })
        //     .catch((err) => {
        //       console.log('package not created: ' + err.message);
        //     });
        // }

  addDocumentSH() {
    try {
      console.log('SignatureService.addDocumentSH()');

      let packageName = localStorage.getItem('packageName');
      let packageID = localStorage.getItem('package_id');
      let documentID = localStorage.getItem('documentID');
      
      // console.log('name: ', name);
      // console.log('email: ', email);
      // console.log('signature: ', signature);
      console.log('packageName: ', packageName);
      console.log('packageID: ', packageID);
      console.log('documentID: ', documentID);
      console.log('accessToken: ', this.accessToken);

      let headers = {
        'Content-type': 'application/octet-stream',
        'Authorization': 'Bearer ' + this.accessToken,
        'x-file-name': 'Corporate Finance Application.pdf',
        'x-convert-document': 'true',
        'x-source': 'API'
      }

      this.callSH(packageID + '/documents', { package_name: 'BMW_' + moment().format('YYYYMMDDHHmmss') }, (xhr: XMLHttpRequest) => {
        console.log('addUserSH: ', xhr.responseText);
        localStorage.setItem('package_id', JSON.parse(xhr.responseText).package_id);
      });
    } catch (error) {
      console.error('SignatureService.addUserSH()', error.stack);
    }
  }
        async uploadDoc() {
      
          // const pdfBytes = await this.readPdf();
      
          // Http.send(pdfBytes);
      
          // Http.onload = (e) => {
          //   console.log('Document state', Http.responseText);
      
          //   var documentObject = JSON.parse(Http.responseText);
          //   localStorage.setItem('documentID', documentObject.documentid);
          //   //this.tempService.addTemplate('verification document');
          // };
        }
      

  addSignatureFieldSH(packageID: string, documentID: string, signature: string): void {
    try {
      console.log('SignatureService.addSignatureFieldSH()');

      console.log('packageID: ', packageID);
      console.log('documentID: ', documentID);

      let signatureField = {
        order: 1,
        page_no: 2,
        field_name: signature + "Signature",
        display: "VISIBLE",
        dimensions: {
          x:76.02683656036446,
          y: 477.67568851784233,
          width: 115.20501138952164,
          height: 27.117552334943635
        },
      }

      if (signature == 'Electronic')
        signatureField["authentication"] = {
          enabled: false,
          sms_otp: {
            enabled: false,
            mobile_number:""
          }
        }

      this.callSH(packageID + '/documents/' + documentID + '/fields/' + (signature == 'Electronic' ? 'electronic_signature' : 'digital_signature'), signatureField, (xhr: XMLHttpRequest) => {
        console.log('addSignatureFieldSH: ', xhr.responseText);
      });
    } catch (error) {
      console.error('SignatureService.addSignatureFieldSH()', error.stack);
    }

        // this.sharePackage();
  }

  addUser(email: string, signature: string) {
    console.log('SignatureService.addUser()');

    let packageName = localStorage.getItem('packageName');
    let packageID = localStorage.getItem('package_id');
    let documentID = localStorage.getItem('documentID');
    let accessToken = localStorage.getItem('accessToken');
    
    console.log('email: ', email);
    console.log('signature: ', signature);
    console.log('packageName: ', packageName);
    console.log('packageID: ', packageID);
    console.log('documentID: ', documentID);
    console.log('accessToken: ', accessToken);

    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      packageID +
      '/workflow/users';
    //var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages/'+packageID +'/documents/'+documentID+'/fields/autoplace';

    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/json');
    Http.setRequestHeader('Accept', 'application/json');
    Http.setRequestHeader('Authorization', 'Bearer' + ' ' + accessToken);

    let user = [
      {
        user_email: email,
        user_name: 'Finance Approver',
        role: 'SIGNER',
        email_notification: true,
        signing_order: 1,
      },
    ];

    Http.send(JSON.stringify(user));

    Http.onload = (e) => {
      console.log('User State: ', Http.responseText);

      this.packageObject.id = packageID;
      this.packageObject.name = packageName;
      this.packageObject.time = new Date();
      this.packageObject.userId = localStorage.getItem('user');
      this.packageObject.signerEmail = email;
      this.packageObject.sender = localStorage.getItem('email');
      this.packageObject.status = '';
      this.packageService
        .addPackageWithOutUserID(this.packageObject)
        .then(() => {
          if (signature == 'Electronic') {
            //this.addElectronic(packageID, documentID);
          } else {
            this.addBlock(packageID, documentID, signature);
          }
        });
    };
  }

  addElectronic(package_id, document_id){
    console.log('SignatureService.addElectronic()');
    let apiUrl ="https://uatapi.signinghub.co.za/v3/packages/" + package_id + "/documents/"+document_id+ "/fields/electronic_signature";

    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/json');
    Http.setRequestHeader('Accept', 'application/json');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    var signature  = {
      "order": 1,
      "page_no": 2,
      "field_name": "E-Sign field",
       "display": "VISIBLE",
      "dimensions": {
         "x":76.02683656036446,
         "y": 477.67568851784233,
         "width": 115.20501138952164,
         "height": 27.117552334943635
      },
      "authentication":{
        "enabled": false,
        "sms_otp":{
          "enabled": false,
          "mobile_number":""

        }
      }

      }

      Http.send(JSON.stringify(signature));


      Http.onload = (e) => {

        console.log('Electronic State', Http.responseText);

        this.sharePackage();
      }
  }

  addBlock(packageID, documentID, signatureBlock) {
    console.log('SignatureService.addBlock()');
    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      packageID +
      '/documents/' +
      documentID +
      '/fields/digital_signature';
    //var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages/'+packageID +'/documents/'+documentID+'/fields/autoplace';

    console.log('access_token', localStorage.getItem('accessToken'));

    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/json');
    Http.setRequestHeader('Accept', 'application/json');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    var signature = {
      order: 1,
      page_no: 2,
      field_name: signatureBlock,
      display: 'VISIBLE',
      dimensions: {
        x: 76.02683656036446,
        y: 477.67568851784233,
        width: 115.20501138952164,
        height: 27.117552334943635,
      },
    };

    Http.send(JSON.stringify(signature));

    Http.onload = (e) => {
      console.log('signature State', Http.responseText);

      this.sharePackage();
    };
  }

  insertSignatureBlock(packageID, documentId, signatureBlock) {
    console.log('SignatureService.insertSignatureBlock()');
    var url = `https://uatapi.signinghub.co.za/v3/packages/${packageID}/documents/${documentId}/fields/electronic_signature`;

    var data = JSON.stringify({
      order: 1,
      page_no: 2,
      field_name: signatureBlock,
      display: 'VISIBLE',
      dimensions: {
        x: 76.02683656036446,
        y: 477.67568851784233,
        width: 115.20501138952164,
        height: 27.117552334943635,
      },
      authentication: {
        enabled: false,
        sms_otp: {
          enabled: false,
          mobile_number: ""
        }
      },
    });

    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    xhr.send(data);

    xhr.onload = (e) => {
      console.log('signature State', xhr.responseText);

      this.sharePackage();
    };
  }

  //Append Template
  addTemplate(name) {
    console.log('SignatureService.addTemplate()');
    var package_id = localStorage.getItem('package_id');
    var documentID = localStorage.getItem('documentID');
    console.log('template in server', name);
    console.log('my document ID', documentID);
    console.log('my package ID', package_id);
    var template_name = name;
    template_name = 'bmw_workflow'; //Rewriting of temolate;

    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      package_id +
      '/documents/' +
      documentID +
      '/template';
    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/json');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    let template = {
      template_name: template_name,
      apply_to_all: true,
    };

    Http.send(JSON.stringify(template));

    Http.onload = (e) => {
      console.log('Template State', Http.responseText);

      this.sharePackage(); //Sharing of document

      console.log('this should be working');
    };
  }

  sharePackage() {
    console.log('SignatureService.sharePackage()');
    var package_id = localStorage.getItem('package_id');

    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' + package_id + '/workflow';

    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/json');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );

    Http.send();

    Http.onload = (e) => {
      console.log('Package State: ', Http.responseText);

      alert('Shared the application for signature.');

      this.router.navigateByUrl('/admin/documents');
    };
  }
}
