<div style="padding:15px">
  <button style="background-color: #1c5cb2" mat-raised-button>
    <mat-icon (click)="addClient()">add</mat-icon>
  </button>
</div>
<mat-form-field style="margin-top: 10px">
  <mat-label>Search</mat-label>
  <input
    matInput
    (keyup)="applyFilter($event)"
    placeholder="Search for user"
    #input
  />
</mat-form-field>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>No.</th>
    <td mat-cell *matCellDef="let element">{{ element.position }}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="fullName">
    <th mat-header-cell *matHeaderCellDef>Full Name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Txt_CrAppCorporateCName }}
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Txt_CrAppCorporateEmailAddr }}
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="telphone">
    <th mat-header-cell *matHeaderCellDef>Telephone</th>
    <td mat-cell *matCellDef="let element">
      {{ element.Txt_CrAppCorporateCellphone }}
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Action</th>
    <td mat-cell *matCellDef="let element">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>visibility</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openClient(element)">View</button>
        <button mat-menu-item (click)="startWorkflow(element)">Start Workflow</button>
        <button mat-menu-item (click)="removeApplication(element)">Delete Application</button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      No data matching the filter "{{ input.value }}"
    </td>
  </tr>
</table>

<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
></mat-paginator>
