<mat-card>
  <div class="profile-upload">
    <label for="file-input">
      <mat-icon class="input"> account_circle </mat-icon>
    </label>

    <input
      id="file-input"
      class="input"
      type="file"
      (change)="uploadProfilePic($event)"
      accept="image/x-png,image/gif,image/jpeg"
    />
  </div>

  <mat-card *ngIf="uploadPercent > 0">
    <mat-card-content>
      <h2 class="example-h2">Upload Status {{ uploadPercent }} %</h2>
    </mat-card-content>
  </mat-card>

  <h2 style="text-align: center">Profile</h2>
  <form [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <br />

    <mat-form-field appearance="fill">
      <mat-label>Surname</mat-label>
      <input matInput formControlName="surname" />
    </mat-form-field>

    <br />

    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>

    <br />

    <button mat-button class="profile-button" (click)="updateProfile()">
      Update
    </button>
  </form>
</mat-card>




