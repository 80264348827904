import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PackageObject } from 'src/app/shared/models/packageObject';
import { DownloadService } from 'src/app/shared/services/download.service';
import { IframeserviceService } from 'src/app/shared/services/iframeservice.service';
import { PackageService } from 'src/app/shared/services/package.service';
import { DownloadmessagebarComponent } from 'src/app/shared/widgets/downloadmessagebar/downloadmessagebar.component';
import { SignoutBarComponent } from 'src/app/shared/widgets/signout-bar/signout-bar.component';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-required-sign',
  templateUrl: './required-sign.component.html',
  styleUrls: ['./required-sign.component.scss'],
})
export class RequiredSignComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<PackageObject>();
  info = [];
  temp = [];

  packages: PackageObject[];
  private queriesSubscription: Subscription;
  isFulfilled = false;
  @Input('search') search: string;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ['sender', 'time', 'completed', 'status', 'sign'];
  link: string;

  constructor(
    private packageService: PackageService,
    private router: Router,
    public dialog: MatDialog,
    private downloadService: DownloadService,
    private iframeService: IframeserviceService
  ) {}

  ngOnInit(): void {
    this.getPackageList();

    setInterval(() => {
      this.getPackageList();
    }, 5000);
  }

  ngAfterViewInit() {
    //this.getPackageList();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getPackageList() {
    this.queriesSubscription = this.packageService.loadedPackages.subscribe(
      (queries: PackageObject[]) => {
        this.dataSource.data = queries;
        console.log(this.dataSource.data);
      }
    );
    this.packageService.fetchPackageToBeSigned();
    console.log('user', localStorage.getItem('user'));
  }

  doFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy() {
    if(this.queriesSubscription){
      this.queriesSubscription.unsubscribe();
    }
  }

  onFulfilled() {
    this.dataSource[0].status = !this.isFulfilled;
  }

  downloadDocument(package_id) {
    this.downloadService.getDownload(package_id);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  goBack() {
    this.router.navigateByUrl('admin');
  }

  signOut() {
    const dialogRef = this.dialog.open(SignoutBarComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  downloadModal(element) {
    const dialogRef = this.dialog.open(DownloadmessagebarComponent, {
      width: '600px',
      data: { package_id: element },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });

    console.log('my user', element);
  }

  signDocument(element){
    console.log(element)
    this.iframeService.getIframe(element.id)
    this.link = localStorage.getItem('linkObject').replace(/['"]+/g, '')
    console.log(this.link);
    window.open(this.link, '_blank');
  }
}
