<mat-card>
  <h1 style="text-align: center; color: black">
    <!-- <button mat-button class="frame-button" (click)="sendDoc()">Send</button> -->
    Please review the application and send for signature
  </h1>
  <div style="padding-left: 2px; text-align: center;display: flex;justify-content: space-evenly;">
    <ng-container *ngIf="url!=null">
      <div>
        <iframe style="height: 700px; width: 900px;" [src]="url"></iframe>
      </div>
      <div style="padding-top: 25px;font-size: 18px;">
        <p style="color: #d3d3d3;">Email of recipient:<br>{{recipientEmail}}</p>
        <p style="color: #d3d3d3;">Signature type:<br>{{signatureType}}</p>
        <div style="display: flex;justify-content: center;">
        <button mat-button class="frame-button" [routerLink]="['/admin/documents']">Cancel</button>
        <div style="padding-left: 30px;padding-right: 30px;"></div>
        <button mat-button class="frame-button" (click)="sendDoc()">Send</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="url==null">
      <div style="color: #d3d3d3; padding-top: 25px;font-size: 18px;">
        <p>Please first select a client details XML file and a pdf document file!</p>
      </div>
    </ng-container>
  </div>
</mat-card>