<mat-toolbar>



</mat-toolbar>
<div id="divider"></div>


<h1 class="title">Please Reset Below:</h1>


<mat-card style="margin-top: 50px;" [ngStyle]="{'background': 'url(' +uri + ') no-repeat 0 0'}">


  <mat-card class="mega_card">

    <img src="assets/bmw-logo-svgrepo-com.svg" id="image_form">

    <form [formGroup]="resetForm">

      <!--  -->


      <mat-form-field appearance="fill">
        <mat-label>email</mat-label>
        <input matInput type="email" required formControlName="email" [ngClass]="{
        'is-invalid':
          (isFormSubmitted && resetForm.controls.email.errors) ||
          (!resetForm.controls.email.pristine &&
          resetForm.controls.email.invalid)
      }" />
      </mat-form-field>

      <!-- Validation messages -->
      <div class="invalid-feedback" *ngIf="
       (isFormSubmitted && resetForm.controls.email.errors) ||
       (!resetForm.controls.email.pristine &&
       resetForm.controls.email.invalid)
     ">
        <div *ngIf="resetForm.controls.email.errors.required">
          Email is required
        </div>
        <div *ngIf="resetForm.controls.email.errors.pattern">
          Enter valid email
        </div>
      </div>


      <button mat-button style="background-color: rgb(41, 181, 228); color: black;  margin-top: 5px;"
        (click)="resetUser()">Submit</button>



      <table>
        <tr>
          <td>
            <a [routerLink]="['']" style="color:  rgb(41, 181, 228); ">Home</a>
          </td>
          <td>
            <a [routerLink]="['/login']" style="color:  rgb(41, 181, 228); float: right;">Login</a>
          </td>
        </tr>
      </table>


    </form>
  </mat-card>




</mat-card>