import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { SnackbarService } from './snackbar.service';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private auth: AngularFireAuth,
    public ngZone: NgZone,
    private router: Router,
    private snackbar: SnackbarService
  ) {}

  //Signing Hub login process
  login(email, pass) {
    var apiUrl = 'https://uatapi.signinghub.co.za/authenticate';
    let username = email;
    let password = pass;

    console.log('username', username);

    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    // Http.setRequestHeader('Access-Control-Request-Headers', 'access-control-allow-headers');
    var data = new FormData();
    data.append('username', username);
    data.append('password', password);

    const encodeFormData = (data) => {
      return Object.keys(data)
        .map(
          (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
    };

    //Makes post reset to signing hub
    Http.send(
      encodeFormData({
        grant_type: 'password',
        client_id: 'Conclude_API',
        client_secret:
          'CA3B9A2D01C04C900DB12DBB206385D55A74FE670AF70B47558F2D51B3F8EB02',
        username: username,
        password: password,
      })
    );

    Http.onload = (e) => {
      console.log('login state', Http.responseText);

      var object = JSON.parse(Http.responseText);
      if (object.access_token != null) {
        localStorage.setItem('accessToken', object.access_token);

        this.signInEmailAndPassword(email, pass)
          .then((data) => {
            let user = data.user;

            localStorage.setItem('user', user.uid);

            localStorage.setItem('email', user.email)
            this.router.navigateByUrl('admin');

            this.snackbar.openSnackBar('Successfully Logged In');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alert('Incorrect Login Details');
      }
    };
  }

  //Create Email And Password
  createEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  signInEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  AuthLogin(provider) {
    return this.auth
      .signInWithPopup(provider)
      .then((result) => {
        console.log('You have been successfully logged in!');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  OAuthProvider(provider) {
    return this.auth
      .signInWithPopup(provider)
      .then((res) => {
        this.ngZone.run(() => {});
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  googleLogin() {
    return this.OAuthProvider(new firebase.auth.GoogleAuthProvider());
  }

  facebookLogin() {
    return this.AuthLogin(new firebase.auth.FacebookAuthProvider());
  }

  signOut() {
    if (localStorage.getItem('user') != '') {
      this.auth.signOut().then(() => {
        this.router.navigateByUrl('');
        localStorage.setItem('user', '');
      });
    } else {
      this.router.navigateByUrl('');
    }
  }

  resetPassword(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
}
