import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PackageService } from 'src/app/shared/services/package.service';
import { IframeComponent } from '../iframe/iframe.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  @ViewChild(IframeComponent)
  iframe: IframeComponent;
  packageService: PackageService;

  constructor(private _formBuilder: FormBuilder, packageService: PackageService) {
    this.packageService = packageService;
  }

  ngOnInit() {
    console.log('StepperComponent.ngOnInit()');
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    localStorage.removeItem('XML');
    localStorage.removeItem('PDF');
    localStorage.removeItem('PDFWithData');
    localStorage.removeItem('SignerEmail'),
    localStorage.removeItem('SignatureType');
    this.packageService.pdfLink = null;
  }

  public onStepChange(event: StepperSelectionEvent): void {
    console.log('Stepper: ', event.selectedIndex);
    if (event.selectedIndex==3)
      this.iframe.getDetails();
    else
      this.iframe.clearURL();
  }
}
