import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PackageObject } from 'src/app/shared/models/packageObject';
import { PackageService } from 'src/app/shared/services/package.service';
import { SignoutBarComponent } from 'src/app/shared/widgets/signout-bar/signout-bar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarState: EventEmitter<any> = new EventEmitter();

  count = 0;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private packageService: PackageService
  ) {}

  ngOnInit(): void {
    // setInterval(() => {
    //   this.getRequiredSignCount();
    // }, 60000);
  }

  toggleSideBar() {
    this.toggleSideBarState.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  signOut() {
    const dialogRef = this.dialog.open(SignoutBarComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`HeaderComponent Dialog result: ${result}`);
    });
  }

  goToRequiredSigned() {
    this.router.navigateByUrl('admin/required');
  }

  getRequiredSignCount() {
    this.packageService.getCountOfToBeSigned().subscribe((data) => {
      this.count = 0;
      data.map((e) => {
        let object = e.payload.doc.data() as PackageObject;
        if (object.status == 'INPROGRESS' || object.status == 'PENDING') {
          this.count = this.count + 1;
        }
      });
    });
  }
}
