import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IframeComponent } from '../../iframe/iframe.component';

@Component({
  selector: 'app-add-signer-item',
  templateUrl: './add-signer-item.component.html',
  styleUrls: ['./add-signer-item.component.scss']
})
export class AddSignerItemComponent implements OnInit {
  form: FormGroup;
  
  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      signerName: ['', Validators.required],
      signerEmail: ['', Validators.required],
      signature: ['', Validators.required]
    });
  }

  saveSigner() {
    if (this.form.valid) {
      console.log('AddSignerItemComponent.saveSigner()');
      localStorage.setItem('SignerName', this.form.value.signerName);
      localStorage.setItem('SignerEmail', this.form.value.signerEmail);
      localStorage.setItem('SignatureType', this.form.value.signature);
    }
  }

  ngOnInit() {

  }
}
