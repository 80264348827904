<div class="container">
  <p
    *ngIf="user?.name != '' || user?.name != null"
    style="margin-top: 20px; text-align: center"
  >
    Hello {{ user?.name }}! Below is your dashboard
  </p>

  <!-- <table>
    <td>
      <ngx-gauge
        [type]="gaugeType"
        [value]="gaugeValue"
        [label]="gaugeLabel1"
        [append]="gaugeAppendText"
        size="115"
      >
      </ngx-gauge>
    </td>
    <td>
      <ngx-gauge
        [type]="gaugeType"
        [value]="gaugeValue"
        [label]="gaugeLabel2"
        [append]="gaugeAppendText"
        size="115"
      >
      </ngx-gauge>
    </td>
    <td>
      <ngx-gauge
        [type]="gaugeType"
        [value]="gaugeValue"
        [label]="gaugeLabel3"
        [append]="gaugeAppendText"
        size="115"
      >
      </ngx-gauge>
    </td>
    <td>
      <ngx-gauge
        [type]="gaugeType"
        [value]="gaugeValue"
        [label]="gaugeLabel4"
        [append]="gaugeAppendText"
        size="115"
      >
      </ngx-gauge>
    </td>
  </table> -->

  <div style="text-align: center" (click)="goToWorkFlow()">
    <button mat-button class="workflow_button">Start New Applicaiton</button>
  </div>
</div>

<div class="speedo_container">
  <img src="assets/dashboardWithNumbers.png"  class="speedo_img"/>
</div>
