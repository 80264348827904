import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { XmladdService } from 'src/app/shared/services/xmladd.service';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-client-add-modal',
  templateUrl: './client-add-modal.component.html',
  styleUrls: ['./client-add-modal.component.scss'],
})
export class ClientAddModalComponent implements OnInit {
  constructor(private http: HttpClient, private xmlAddService: XmladdService) {}

  xmlData;
  clientObject$: Observable<Array<any>>;

  ngOnInit(): void {
    
  }

  saveImageMessage(event: any) {
    event.preventDefault();
    const file = event.target.files[0];
    console.log(file);
    // Clear the selection in the file picker input.
    const imageForm = <HTMLFormElement>document.getElementById('image-form');
    imageForm.reset();

    this.getClient(file).subscribe(data => {
      console.warn('CLIENT DATA: ', data)
      this.xmlAddService.xmlDataStore(data)
    });
  }

  onImageClick(event: any) {
    event.preventDefault();
    document.getElementById('mediaCapture').click();

    console.log(document.getElementById('mediaCapture').click());
  }

  getClient(file) {
    var url = '../../../assets/' + file.name;
    return this.http
      .get(url, { responseType: "text" })
      .pipe(
        switchMap(async xml => await this.parseXmlToJson(xml))
      );
  }

  async parseXmlToJson(xml) {
    return await xml2js
      .parseStringPromise(xml, { explicitArray: false })
      .then(response => response.topmostSubform);
  }
}
