import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { PackageObject } from '../models/packageObject';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  packageObject = {} as PackageObject;
  loadedPackages = new Subject<PackageObject[]>();
  private querySub: Subscription[] = [];
  private availablePackages: PackageObject[] = [];
  array =[];
  pdfLink;

  constructor(
    private database: AngularFirestore,
    private http: HttpClient,
    private documentService: DocumentService
  ) {}

  makeid(length) {
    var result = [];
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join('');
  }

  // Create package - package.html
  createPackage() {
    console.log('PackageService.createPackage()');
    this.getPackageList(localStorage.getItem('user')).subscribe((snapshot) => {
      let size = snapshot.size + 1;

      var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages';
      var packageName = 'BMW' + size;

      console.log('access_token', localStorage.getItem('accessToken'));

      const Http = new XMLHttpRequest();
      Http.open('POST', apiUrl, true);
      Http.setRequestHeader('Content-type', 'application/json');
      Http.setRequestHeader(
        'Authorization',
        'Bearer' + ' ' + localStorage.getItem('accessToken')
      );

      console.log('package name', packageName);

      var packageItem = {
        package_name: packageName,
      };

      Http.send(JSON.stringify(packageItem));

      Http.onload = (e) => {
        console.log('Package state', Http.responseText);

        if (Http.responseText) {
          let packageValue = JSON.parse(Http.responseText);

          localStorage.setItem('package_id', packageValue.package_id);

          this.documentService.uploadDoc();
          this.packageObject.id = packageValue.package_id;

          this.packageObject.name = packageName;
          this.packageObject.time = new Date();
          this.packageObject.userId = localStorage.getItem('user');

          this.addPackageWithOutUserID(this.packageObject)
            .then(() => {
              console.log('package created');
            })
            .catch((err) => {
              console.log('package not created: ' + err.message);
            });
        }
      };
    });
  }

  addPackage(packageObject) {
    console.log('PackageService.addPackage()');
    console.log('user id', packageObject.userId);
    return this.database
      .collection('PackageList')
      .doc(packageObject.userId)
      .collection('package')
      .add(packageObject);
  }

  updatePackage(userId, key) {
    console.log('PackageService.updatePackage()');
    return this.database
      .collection('PackageList/' + userId + '/package')
      .doc(key)
      .update({ status: localStorage.getItem('status') });
  }

  fetchPackages(firebase_user_id) {
    console.log('PackageService.fetchPackages()');
    this.querySub.push(
      this.database
        .collection('PackageList')
        .doc(firebase_user_id)
        .collection('package', (ref) => ref.orderBy('time'))
        .snapshotChanges()
        .pipe(
          map((queryArray) => {
            return queryArray.map((doc) => {
              const data = doc.payload.doc.data() as PackageObject;
              const key = doc.payload.doc.id;

              var apiUrl =
                'https://uatapi.signinghub.co.za/v3/packages/' +
                data.id +
                '/log';

              const Http = new XMLHttpRequest();
              Http.open('GET', apiUrl, true);
              Http.setRequestHeader('Content-type', 'application/json');
              Http.setRequestHeader(
                'Authorization',
                'Bearer' + ' ' + localStorage.getItem('accessToken')
              );

              Http.send();

              Http.onload = (e) => {
                localStorage.setItem('status', '');

                // console.log(Http.responseText);
                let packageValue = JSON.parse(Http.responseText);

                // console.log('status', packageValue.package_status);

                localStorage.setItem('status', packageValue.package_status);

                if (
                  data.status == '' ||
                  data.status == null ||
                  data.status != localStorage.getItem('status')
                ) {
                  this.updatePackage(localStorage.getItem('user'), key);
                }
              };

              return {
                key: key,
                id: data.id,
                name: data.name,
                time: data.time,
                userId: data.userId,
                status: data.status,
              };
            });
          })
        )
        .subscribe((packages: any) => {
          this.availablePackages = packages;
          this.loadedPackages.next([...this.availablePackages]);
        })
    );
  }

  getPackageList(firebase_user_id) {
    console.log('PackageService.getPackageList()');
    return this.database
      .collection('PackageList')
      .doc(firebase_user_id)
      .collection('package')
      .get();
  }

  getSigningDetails(package_id) {
    console.log('PackageService.getSigningDetails()');
    let url =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      package_id +
      '/workflow/users';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer' + ' ' + localStorage.getItem('accessToken'),
      }),
      responseType: 'text' as 'json',
    };

    return this.http.get(url, httpOptions);
  }

  checkPackageComplete(firebase_user_id) {
    console.log('PackageService.checkPackageComplete()');
    return this.database
      .collection('PackageList/' + firebase_user_id + '/package')
      .snapshotChanges()
      .subscribe((data) => {
        data.map((e) => {
          let info = e.payload.doc.data() as PackageObject;
          if (
            (info.status == 'COMPLETED' && info.downloadUrl == '') ||
            info.downloadUrl == null
          ) {
            var apiUrl =
              'https://uatapi.signinghub.co.za/v3/packages/' +
              info.id +
              '/base64?';
            const Http = new XMLHttpRequest();
            Http.open('GET', apiUrl, true);
            Http.setRequestHeader('Content-type', 'application/octet-stream');
            Http.setRequestHeader(
              'Authorization',
              'Bearer' + ' ' + localStorage.getItem('accessToken')
            );

            Http.send();
            Http.onload = (e) => {
              let base64 = JSON.parse(Http.responseText);
              this.dataURItoBlob(base64.base64);
              const blob = this.dataURItoBlob(base64.base64);
              const url = URL.createObjectURL(blob);
            };
          }
        });
      });
  }

  dataURItoBlob(dataURI) {
    console.log('PackageService.dataURItoBlob()');
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  getPackageListWithOutUserID() {
    console.log('PackageService.getPackageListWithOutUserID()');
    return this.database.collection('PackageList').get();
  }

  addPackageWithOutUserID(packageObject) {
    console.log('PackageService.addPackageWithOutUserID()');
    return this.database.collection('PackageList').add(packageObject);
  }

  checkPackageCompleteWithUserId() {
    console.log('PackageService.checkPackageCompleteWithUserId()');
    return this.database
      .collection('PackageList', (ref) =>
        ref.where('userID', '==', localStorage.getItem('user'))
      )
      .snapshotChanges()
      .subscribe((data) => {
        data.map((e) => {
          let info = e.payload.doc.data() as PackageObject;
          if (
            (info.status == 'COMPLETED' && info.downloadUrl == '') ||
            info.downloadUrl == null
          ) {
            var apiUrl =
              'https://uatapi.signinghub.co.za/v3/packages/' +
              info.id +
              '/base64?';
            const Http = new XMLHttpRequest();
            Http.open('GET', apiUrl, true);
            Http.setRequestHeader('Content-type', 'application/octet-stream');
            Http.setRequestHeader(
              'Authorization',
              'Bearer' + ' ' + localStorage.getItem('accessToken')
            );

            Http.send();
            Http.onload = (e) => {
              let base64 = JSON.parse(Http.responseText);
              this.dataURItoBlob(base64.base64);
              const blob = this.dataURItoBlob(base64.base64);
              const url = URL.createObjectURL(blob);
            };
          }
        });
      });
  }

  fetchPackagesWithoutUserID() {
    console.log('PackageService.fetchPackagesWithoutUserID()');
    this.querySub.push(
      this.database
        .collection('PackageList', (ref) =>
          ref.where('userId', '==', localStorage.getItem('user'))
        )
        .snapshotChanges()
        .pipe(
          map((queryArray) => {
            return queryArray.map((doc) => {
              const data = doc.payload.doc.data() as PackageObject;
              const key = doc.payload.doc.id;

              var apiUrl =
                'https://uatapi.signinghub.co.za/v3/packages/' +
                data.id +
                '/log';

              const Http = new XMLHttpRequest();
              Http.open('GET', apiUrl, true);
              Http.setRequestHeader('Content-type', 'application/json');
              Http.setRequestHeader(
                'Authorization',
                'Bearer' + ' ' + localStorage.getItem('accessToken')
              );

              Http.send();

              Http.onload = (e) => {
                localStorage.setItem('status', '');

                // console.log(Http.responseText);
                let packageValue = JSON.parse(Http.responseText);

                // console.log('status', packageValue.package_status);

                localStorage.setItem('status', packageValue.package_status);

                if (
                  data.status == '' ||
                  data.status == null ||
                  data.status != localStorage.getItem('status')
                ) {
                  this.updatePackageWIthoutUserId(key, packageValue.package_status);
                }
              };

              return {
                key: key,
                id: data.id,
                name: data.name,
                time: data.time,
                userId: data.userId,
                status: data.status,
              };
            });
          })
        )
        .subscribe((packages: any) => {
          this.availablePackages = packages;
          this.loadedPackages.next([...this.availablePackages]);
        })
    );
  }

  createPackageWithoutUserID() {
    console.log('PackageService.createPackageWithoutUserID()');
    this.getPackageListWithOutUserID().subscribe((snapshot) => {
      let size = snapshot.size + 1;

      var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages';
      var packageName = 'BMW' + size;

      console.log('access_token', localStorage.getItem('accessToken'));

      const Http = new XMLHttpRequest();
      Http.open('POST', apiUrl, true);
      Http.setRequestHeader('Content-type', 'application/json');
      Http.setRequestHeader(
        'Authorization',
        'Bearer' + ' ' + localStorage.getItem('accessToken')
      );

      console.log('package name', packageName);

      var packageItem = {
        package_name: packageName,
      };

      Http.send(JSON.stringify(packageItem));

      Http.onload = (e) => {
        console.log('Package state', Http.responseText);

        if (Http.responseText) {
          let packageValue = JSON.parse(Http.responseText);

          localStorage.setItem('package_id', packageValue.package_id);

          this.documentService.uploadDoc();
          this.packageObject.id = packageValue.package_id;

          this.packageObject.name = packageName;
          this.packageObject.time = new Date();
          this.packageObject.userId = localStorage.getItem('user');

          this.addPackageWithOutUserID(this.packageObject)
            .then(() => {
              console.log('package created');
            })
            .catch((err) => {
              console.log('package not created: ' + err.message);
            });
        }
      };
    });
  }

  getPackageCountByUerWithoutUserID() {
    console.log('PackageService.getPackageCountByUerWithoutUserID()');
    return this.database
      .collection('PackageList', (ref) =>
        ref.where('userId', '==', localStorage.getItem('user'))
      )
      .get();
  }

  updatePackageWIthoutUserId(key, status) {
    console.log('PackageService.updatePackageWIthoutUserId()');
    return this.database
      .collection('PackageList'
      )
      .doc(key)
      .update({ status: status });
  }

  updateCompletedTimeWIthoutUserId(key, time) {
    console.log('PackageService.updateCompletedTimeWIthoutUserId()');
    return this.database
      .collection('PackageList', (ref) =>
        ref.where('userId', '==', localStorage.getItem('user'))
      )
      .doc(key)
      .update({ completedTime: time });
  }

  getCompletedPackages() {
    console.log('PackageService.getCompletedPackages()');
    return this.database
      .collection('PackageList', (ref) =>
        ref
          .where('userId', '==', localStorage.getItem('user'))
          .where('status', '==', 'COMPLETED')
      )
      .get();
  }

  getInProgressPackages() {
    console.log('PackageService.getInProgressPackages()');
    return this.database
      .collection('PackageList', (ref) =>
        ref
          .where('userId', '==', localStorage.getItem('user'))
          .where('status', '==', 'INPROGRESS')
      )
      .get();
  }

  getTotalCompletedPackages() {
    console.log('PackageService.getTotalCompletedPackages()');
    return this.database
      .collection('PackageList', (ref) =>
        ref.where('status', '==', 'COMPLETED')
      )
      .get();
  }


  fetchPackageToBeSigned(){
    console.log('PackageService.fetchPackageToBeSigned()');
    this.querySub.push(
      this.database
        .collection('PackageList', (ref) =>
          ref.where('signer', '==', localStorage.getItem('email'))
        )
        .snapshotChanges()
        .pipe(
          map((queryArray) => {
            return queryArray.map((doc) => {
              const data = doc.payload.doc.data() as PackageObject;
              const key = doc.payload.doc.id;

                
              let infoDetail ={
                actions: [],
                package_status: ''
              };

              localStorage.setItem('status','')
               this.getSigningStatus(data.id).subscribe((pack:any) =>{
    
               
                console.log('signing hub',pack);
            
              

            infoDetail = pack;

            console.log('my fire', infoDetail)
                
              localStorage.setItem('status',infoDetail.package_status)

            
                  
                  if(data.status !=infoDetail.package_status){
                   this.updatePackageWIthoutUserId(key,infoDetail.package_status);


                 



                  }


                  
                  if( (data.completedTime == '') || (data.completedTime == null)  ){


                    if(infoDetail.actions[0].action_type == "COMPLETED"){
                    this.updateCompletedTimeWIthoutUserId(key,infoDetail.actions[0].date_time);;
                    
                  }                  }

                 

              })
             
              console.log('outside',  localStorage.getItem('status'))
              
              return {
                key: key,
                id: data.id,
                name: data.name,
                time: data.time,
                userId: data.userId,
                signer: data.signerEmail,
                sender: data.sender,
                status: data.status,
                completedTime: data.completedTime
              };

            
            });
          })
        )
        .subscribe((packages: any) => {
          this.availablePackages = packages;
          this.loadedPackages.next([...this.availablePackages]);
        })
    );

  
  }

  


  getCountOfToBeSigned(){
    console.log('PackageService.getCountOfToBeSigned()');
    return this.database
        .collection('PackageList', (ref) =>
          ref.where('signer', '==', localStorage.getItem('email'))
        ).snapshotChanges()
  }


  
  freshDatabase(){
    console.log('PackageService.freshDatabase()');
    this.database
        .collection('PackageList').snapshotChanges().subscribe(info => {
        
        info.map(e => {

          const data = e.payload.doc.data() as PackageObject;
          const key = e.payload.doc.id;
          let infoDetail ={
            package_status: ''
          };
          this.getSigningStatus(data.id).subscribe((pack:any) =>{

        

             let infoDetail ={
                package_status: ''
              };
              this.getSigningStatus(data.id).subscribe((pack:any) =>{
    
               
            
                infoDetail = pack;

                localStorage.setItem('status',infoDetail.package_status)
                  
                  // if(data.status !=infoDetail.package_status){
                  //  this.updatePackageWIthoutUserId(key,infoDetail.package_status);
                  // }

                  return infoDetail;
              })
             
              

          })
       

       
        })



        });

     


  }


  getSigningStatus(key){
    console.log('PackageService.getSigningStatus()');
  
let url ='https://uatapi.signinghub.co.za/v3/packages/' +key+'/log';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
      'Authorization':
          'Bearer' + ' ' + localStorage.getItem('accessToken')
      })
    }
    
    
  return  this.http.get(url,httpOptions)
  }


  getSignerPackages(signer){
    console.log('PackageService.getSignerPackages()');
   this.database
    .collection('PackageList', (ref) =>
      ref.where('signer', '==', signer)
    )
    .snapshotChanges().subscribe(item => {

      item.map(e =>{

        const data = e.payload.doc.data() as PackageObject;
        const key = e.payload.doc.id;
        let infoDetail ={
          package_status: ''
        };
      this.getSigningStatus(data.id).subscribe(x =>{

          this.array.push(x);

          return this.array;
      })
      }
      )
    })
  }

  createPackageNoFirebase() {
    console.log('PackageService.createPackageNoFirebase()');
    this.getPackageList(localStorage.getItem('user')).subscribe((snapshot) => {
      let size = snapshot.size + 1;

      var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages';
      var packageName = 'BMW' + size;

      console.log('access_token', localStorage.getItem('accessToken'));

      const Http = new XMLHttpRequest();
      Http.open('POST', apiUrl, true);
      Http.setRequestHeader('Content-type', 'application/json');
      Http.setRequestHeader(
        'Authorization',
        'Bearer' + ' ' + localStorage.getItem('accessToken')
      );

      console.log('package name', packageName);

      var packageItem = {
        package_name: packageName,
      };

      Http.send(JSON.stringify(packageItem));

      Http.onload = (e) => {
        console.log('Package state', Http.responseText);

        if (Http.responseText) {
          let packageValue = JSON.parse(Http.responseText);

          localStorage.setItem('package_id', packageValue.package_id);

          this.documentService.uploadDocNoFirebase();
          this.packageObject.id = packageValue.package_id;

        
        }
      };
    });
  }



  createPackageDocument(item){
    console.log('PackageService.createPackageDocument()');
    this.getPackageList(localStorage.getItem('user')).subscribe((snapshot) => {
      let size = snapshot.size + 1;

      var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages';
      var packageName = 'BMW' + size;

      console.log('access_token', localStorage.getItem('accessToken'));

      const Http = new XMLHttpRequest();
      Http.open('POST', apiUrl, true);
      Http.setRequestHeader('Content-type', 'application/json');
      Http.setRequestHeader(
        'Authorization',
        'Bearer' + ' ' + localStorage.getItem('accessToken')
      );

    

      var packageItem = {
        package_name: packageName,
      };

      Http.send(JSON.stringify(packageItem));

      Http.onload = (e) => {
        console.log('Package state', Http.responseText);

        if (Http.responseText) {
          let packageValue = JSON.parse(Http.responseText);

          localStorage.setItem('package_id', packageValue.package_id);

          this.documentService.uploadDocItem(item);
          this.packageObject.id = packageValue.package_id;

        
        }
      };
    });

  }



}
