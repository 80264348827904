<mat-toolbar>
  <div class="logo_container">
    <img src="assets/bmw-logo-svgrepo-com.svg" class="logo" style="padding-left: 280px;" />
  </div>
  <button mat-button class="login_button" (click)="login()">Login</button>
</mat-toolbar>
<div id="divider"></div>

<div class="img_container">
  <img src="assets/landing.jpg" class="header_img"/>
</div>

<p class="slogan">
  Nobody is perfect. But if you drive a <br />
  <br />
  <br />
  <span style="color: #2A88B7">BMW</span> your're pretty close
</p>
