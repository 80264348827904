import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadService } from '../../services/download.service';


export interface DialogData {
  package_id: any;
}

@Component({
  selector: 'app-downloadmessagebar',
  templateUrl: './downloadmessagebar.component.html',
  styleUrls: ['./downloadmessagebar.component.scss'],
})
export class DownloadmessagebarComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {}

  yesDownload() {
    console.log('my package', this.data);

    this.downloadDocument(this.data.package_id);
    this.downloadService.countDownload();
    this.dialog.closeAll();
  }

  noDownload() {
    this.dialog.closeAll();
  }

  downloadDocument(package_id) {
    this.downloadService.getDownload(package_id);
  }
}
