import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user';
import { UsersService } from 'src/app/shared/services/users.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  user = {} as User;

  constructor(
    private userService: UsersService,
    private router: Router,
    private users: UsersService
  ) {}

  ngOnInit(): void {
    this.getProfile();
  }

  goToProfile() {
    this.router.navigateByUrl('admin/profile');
  }

  getProfile() {
    this.userService.retrieveProfileImage().subscribe((data) => {
      this.user = data.payload.data() as User;
    });
  }
}
