import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/shared/models/user';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  user = {} as User;
  color = 'secondary';
  mode = 'determinate';
  value = 50;
  bufferValue = 75;

  uploadPercent: number;

  constructor(
    private userService: UsersService,
    public fb: FormBuilder,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {
    this.form = fb.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      email: [''],
    });
  }

  ngOnInit(): void {
    this.getUserDetails();
  }

  updateProfile() {
    if (this.form.valid) {
      this.user.name = this.form.value.name;
      this.user.surname = this.form.value.surname;
      this.user.userId = localStorage.getItem('user');

      this.userService.addProfile(this.user).then(() => {
        this.snackbarService.openSnackBar('Successfully updated profile');
      });
    }
  }

  getUserDetails() {
    this.userService.retrieveProfile().subscribe((data) => {
      let user = data.data() as User;

      this.form = this.fb.group({
        name: [user.name, [Validators.required]],
        surname: [user.surname, [Validators.required]],
        email: [user.email],
      });

     

    });
  }

  uploadProfilePic(event) {
    this.userService.uploadPhoto(event).subscribe((data: number) => {
      this.uploadPercent = data;
      console.log(this.uploadPercent);
    });
  }

  
  
}
