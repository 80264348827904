import { element } from 'protractor';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as xml2js from 'xml2js';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map, switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

export interface Client {
  Txt_CrAppCorporateEmailAddr: string;
  Txt_CrAppCorporateCellphone: string;
  Txt_CrAppCorporateCName: string;
}
@Injectable({
  providedIn: 'root',
})
export class XmladdService {
  private querySub: Subscription[] = [];
  clientObject$: Observable<Array<any>>;
  clients: Observable<any>;
  loadedClients;
  selectedFile = null;
  uploadPercent: any;
  downloadU: any;
  uniqkey: any;
  today: any = new Date();
  date =
    this.today.getDate() +
    '' +
    (this.today.getMonth() + 1) +
    '' +
    this.today.getFullYear();
  time = this.today.getHours() + '' + this.today.getMinutes();
  dateTime = this.date + '' + this.time;

  constructor(
    private http: HttpClient,
    private database: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  getClient(file) {
    var url = file;
    return this.http.get(url, { responseType: 'text' }).pipe(
      switchMap(async (xml) => {
        await this.parseXmlToJson(xml);
      })
    );
  }

  async parseXmlToJson(xml) {
    const parser = new xml2js.Parser({ explicitArray: false });
    parser
      .parseStringPromise(xml)
      .then(function (result) {
        console.log(result);
        console.log('Done');
      })
      .catch(function (err) {
        console.log(err.message);
      });
  }

  xmlDataStore(data) {
    console.log(data);
    return this.database
      .collection('XMLData')
      .doc(localStorage.getItem('user'))
      .collection('clients')
      .add(data);
  }

  xmlGetClients() {
    return this.database
      .collection('XMLData')
      .doc(localStorage.getItem('user'))
      .collection('clients')
      .snapshotChanges()
      .pipe(
        map((clients) => {
          return clients.map((a) => {
            const data = a.payload.doc.data() as Client;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  xmlRemoveClient(element) {
    return this.database
      .collection('XMLData')
      .doc(localStorage.getItem('user'))
      .collection('clients')
      .doc(element)
      .delete();
  }

  startWorkflow(element) {
    return this.database
      .collection('XMLData')
      .doc(localStorage.getItem('user'))
      .collection('clients')
      .doc(element)
      .get();
  }

  uploadXML() {}

  firebaseXMLUpload(event) {
    const file = event;
    this.uniqkey = 'BMW' + this.dateTime;
    const filePath = 'BMWXML/' + this.uniqkey;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadU = fileRef.getDownloadURL().subscribe((urlPath) => {
            console.log(urlPath);

            this.database.collection('BMWXML').add({
              name: this.uniqkey,
              downloadUrl: urlPath,
            });
          });
        })
      )
      .subscribe();
    return (this.uploadPercent = task.percentageChanges());
  }

  getFirebaseXML() {
    return this.database
      .collection('BMWXML', (ref) => ref.where('name', '==', 'BMW1720211437'))
      .snapshotChanges();
  }
}
