import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomepageComponent } from './layouts/homepage/homepage.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialImportsModule } from './material-imports/material-imports.module';
import { AdminModule } from './layouts/admin/admin.module';
import { ComponentsModule } from './components/components.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule} from '@angular/fire/firestore'
import { environment } from 'src/environments/environment';
import { DownloadmessagebarComponent } from './shared/widgets/downloadmessagebar/downloadmessagebar.component';
import { SignoutBarComponent } from './shared/widgets/signout-bar/signout-bar.component';
import { SnackbarComponent } from './shared/widgets/snackbar/snackbar.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { NgxGaugeModule } from 'ngx-gauge';




@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    DownloadmessagebarComponent,
    SignoutBarComponent,
    SnackbarComponent
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialImportsModule,
    AdminModule,
    ComponentsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGaugeModule

  ],



  providers: [],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA]
})
export class AppModule {}
