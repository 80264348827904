import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from '../app/components/auth/sign-in/signin.component';
import { AddSignerComponent } from './components/add-signer/add-signer.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ResetComponent } from './components/auth/reset/reset.component';
import { ClientApplicationsComponent } from './components/client-applications/client-applications.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RequiredSignComponent } from './components/required-sign/required-sign.component';
import { AddSignerItemComponent } from './components/stepper/add-signer-item/add-signer-item.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { AdminComponent } from './layouts/admin/admin.component';
import { DashboardComponent } from './layouts/admin/dashboard/dashboard.component';
import { DocumentsComponent } from './layouts/admin/documents/documents.component';
import { StatsComponent } from './layouts/admin/stats/stats.component';
import { WorkflowComponent } from './layouts/admin/workflow/workflow.component';
import { HomepageComponent } from './layouts/homepage/homepage.component';
import { IframeserviceService } from './shared/services/iframeservice.service';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'home', component: HomepageComponent },
  { path: 'login', component: SigninComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'register', component: RegisterComponent }, 

  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'workflow', component: StepperComponent },
      { path: 'stats', component: StatsComponent },
      { path: 'documents', component: DocumentsComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'required', component: RequiredSignComponent },
      { path: 'addSigner', component: AddSignerItemComponent },
      {path: 'stepper', component: StepperComponent},
      {path: 'applications', component: ClientApplicationsComponent},
      {path: 'iframe', component: IframeComponent}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
