import { MaterialImportsModule } from './../../material-imports/material-imports.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { WorkflowComponent } from './workflow/workflow.component';
import { StatsComponent } from './stats/stats.component';
import { DocumentsComponent } from './documents/documents.component';

import { NgxGaugeModule } from 'ngx-gauge';

@NgModule({
  declarations: [
    AdminComponent,
    DashboardComponent,
    WorkflowComponent,
    StatsComponent,
    DocumentsComponent
  ],
  imports: [
    CommonModule,
    MaterialImportsModule,
    AppRoutingModule,
    ComponentsModule,
    NgxGaugeModule
  ],
})
export class AdminModule {}
