import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { PackageObject } from '../models/packageObject';

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import * as xml2js from 'xml2js';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TestWorkflowService {
  packageObject = {} as PackageObject;
  arrayItem;
  arrayPdf = [];

  constructor(
    private http: HttpClient,
    private database: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router
  ) {}

  getPackageListWithOutUserID() {
    return this.database.collection('PackageList').get();
  }

  addPackageWithOutUserID(packageObject) {
    return this.database.collection('PackageList').add(packageObject);
  }

  createPackage(xml) {
    // var temp_xml_data = localStorage
    //   .getItem('XML')
    //   .substr(localStorage.getItem('XML').indexOf(',') + 1);
    // this.uploadDoc(atob(temp_xml_data));
    this.getPackageListWithOutUserID().subscribe((snapshot) => {
      let size = snapshot.size + 1;

      var apiUrl = 'https://uatapi.signinghub.co.za/v3/packages';
      var packageName = 'BMW' + size;

      console.log('access_token', localStorage.getItem('accessToken'));

      const Http = new XMLHttpRequest();
      Http.open('POST', apiUrl, true);
      Http.setRequestHeader('Content-type', 'application/json');
      Http.setRequestHeader(
        'Authorization',
        'Bearer' + ' ' + localStorage.getItem('accessToken')
      );

      console.log('package name', packageName);

      localStorage.setItem('packageName', packageName);

      var packageItem = {
        package_name: packageName,
      };

      Http.send(JSON.stringify(packageItem));

      Http.onload = (e) => {
        console.log('Package state', Http.responseText);

        if (Http.responseText) {
          let packageValue = JSON.parse(Http.responseText);

          localStorage.setItem('package_id', packageValue.package_id);

          var temp_xml_data = localStorage
            .getItem('XML')
            .substr(localStorage.getItem('XML').indexOf(',') + 1);
          this.uploadDoc(atob(temp_xml_data));
        }
      };
    });
  }

  async uploadDoc(xml_file) {
    this.retrieveXML(xml_file);
    // await this.readPdf(xml_file);
    var package_id = localStorage.getItem('package_id');
    var apiUrl =
      'https://uatapi.signinghub.co.za/v3/packages/' +
      package_id +
      '/documents';
    const Http = new XMLHttpRequest();
    Http.open('POST', apiUrl, true);
    Http.setRequestHeader('Content-type', 'application/octet-stream');
    Http.setRequestHeader(
      'Authorization',
      'Bearer' + ' ' + localStorage.getItem('accessToken')
    );
    Http.setRequestHeader('x-file-name', 'BMW.pdf');
    Http.setRequestHeader('x-convert-document', 'true');
    Http.setRequestHeader('x-source', 'API');

    const pdfBytes = await this.readPdf(xml_file);

    Http.send(pdfBytes);

    Http.onload = (e) => {
      console.log('Document state', Http.responseText);

      var documentObject = JSON.parse(Http.responseText);
      localStorage.setItem('documentID', documentObject.documentid);
      this.router.navigateByUrl('admin/addSigner');
    };
  }

  dataURItoArrayBuffer(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return ab;
  }

  async readPdf(xml_file) {
    const url = '../../../assets/bmw.pdf';

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdf = await await PDFDocument.load(existingPdfBytes);
    const pdfDoc = pdf;

    await (
      await PDFDocument.load(existingPdfBytes)
    )
      .getForm()
      .getFields()
      .forEach((field) => {
        console.log(field.getName());
        this.arrayPdf.push({ value: field.getName() });
      });

    const form = pdfDoc.getForm();

    let count = 0;
    for (let x = 0; x < this.arrayItem.length; x++) {
      let xmlFieldName = Object.keys(this.arrayItem[x]);
      let textToSet = Object.values(this.arrayItem[x]);

      for (let i = 0; i < this.arrayPdf.length; i++) {
        let textField = this.arrayPdf[i].value;

        if (
          textField.includes(xmlFieldName) &&
          !textField.includes('topmostSubform[0].Page1[0].G1[0]') &&
          !textField.includes('topmostSubform[0].Page2[0].G1[0]')
        ) {
          console.log(xmlFieldName + ' ===> ' + textField);
          form.getTextField(textField).setText(String(textToSet));
          console.log(
            'Text: ' + textToSet + ' has been set to ' + textField + ' field.'
          );
          break;
        }
      }
    }

    const pdfBytes = await pdf.save();
    return pdfBytes;
  }

  retrieveXML(xml_file) {
    console.log('INCOMING: ', xml_file)
    this.parseXML(xml_file).then((info) => {
      console.log('my data', info);

      this.arrayItem = info;
    });
  }

parseXML(data) {
    return new Promise((resolve) => {
      var k: string | number,
        arr = [],
        parser = new xml2js.Parser({
          trim: true,
          explicitArray: false,
        });
      parser.parseString(data, function (err, result) {
        console.log(result.topmostSubform);

        let xmlData = [];
        let page2Data = result.topmostSubform.Page2;
        console.log('Page 2 data: ', page2Data);
        delete result.topmostSubform.Page2;
        console.log('Page 2 removed: ', result);
        let newResult = result.topmostSubform;
        let tempResult = { ...newResult, ...page2Data };
        console.log('New Result: ', tempResult);

        for (const item in tempResult) {
          let property = item;
          xmlData.push({ [`${property}`]: tempResult[item] });
        }
        console.log('CLIENT OBJECT', xmlData);
        resolve(xmlData);
      });
    });
  }
}
