import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(
      control?.parent?.invalid && control?.parent?.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {
  resetForm: FormGroup;
  isFormSubmitted: boolean = false;
  matcher = new MyErrorStateMatcher();

 
  uri ="../../../../assets/landing.jpg";

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private snackBarService: SnackbarService
  ) {
    // Regex patterns
    const PAT_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$';

    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(PAT_EMAIL)]],
    });
  }

  ngOnInit(): void {}

  resetUser() {
    if (this.resetForm.valid) {
      this.loginService.resetPassword(this.resetForm.value.email).then(() => {
        this.snackBarService.openSnackBar('Reset link sent. Check your email');
      });
    } else {
      alert('Enter Email');
    }
  }
}
